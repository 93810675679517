<template src='./activityCumulativeAttendance.html'></template>
<script>
import bar from "../../Components/AttendanceAnalysisBarGraph";
import pie from "../../Components/AttendanceAnalysisPieChart";
import LectureRepository from "../../Repository/Lecture";
import UserRepository from "../../Repository/User";
import SemesterRepository from "../../Repository/Semester";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SubjectRepository from "../../Repository/Subject";
import { subjectTypes } from "../../Constants/Utils/Statics";
import arrayOps from "../../Services/Utils/arrayOps";
import networkManager from "../../NetworkManager/index";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../Services/Utils/DateTimeFormatter";
import { createMultiSheetStyledExcel } from "../../utils/excel";

export default {
  name: "activityAttendanceAnalysis",
  data() {
    return {
      allLecItem: [],
      overallForAllLec: [
        {
          text: 'Date',
          value: 'startTime'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      selectedLecForTable: [],
      selectedDataForAllocation: {},
      errorForSelection: [],
      conductedCount: 0,
      nonConductedCount: 0,
      cancelCount: 0,
      today: '',
      listForDept: [],
      subjectIdsAllocated: [],
      flagForTable: false,
      lecObjForFaculty: {},
      semUserObj: {},
      objForAllSubDetails: {},
      objForStudentLec: {},
      menu: false,
      menu1: false,
      allFacultyName: [],
      allLecturesData: [],
      allFacultyName: [],
      overallHeaders: [
        {
          text: "Department",
          value: "department",
          width: '100px'
        },
        {
          text: "Course Year",
          value: "courseYear",
          width: '100px'
        },
        {
          text: "Course",
          value: "subjectName",
          width: '200px'
        },
        {
          text: "Allocation",
          value: "allocation",
          width: '200px'
        },
        {
          text: "Total Lectures Conducted",
          value: "conductedCount"
        },
        {
          text: "Total Lectures Not Conducted",
          value: "notConductedCount"
        },
        {
          text: "Total Lectures Cancelled",
          value: "cancelCount"
        },
        {
          text: "Total Students Allocated",
          value: "allocatedStudentCount"
        },
        {
          text: "Overall Attendance (%)",
          value: "overallAttendance"
        }
      ],
      overallHeadersForStudent: [
        {
          text: "Course",
          value: "subjectName",
          width: '200px'
        },
        {
          text: "Allocation",
          value: "allocation",
          width: '200px'
        },
        {
          text: "Total Lectures Conducted",
          value: "conductedCount"
        },
        {
          text: "Total Lecture Attended",
          value: "attendedCount"
        },
        {
          text: "Overall Attendance (%)",
          value: "overallAttendance"
        }
      ],
      headerForAllocationDummy: [
        {
          text: "Name",
          value: "fullName",
          width: '200px'
        },
        {
          text: "Roll No",
          value: "rollNumber",
          width: '150px'
        },
        {
          text: "Mentor Name",
          value: "mentor",
          width: '200px'
        },
        {
          text: "PRN",
          value: "collagePRNNo",
          width: '150px'
        },
        {
          text: "Overall attendance",
          value: "overallAttendance",
          width: '150px'
        }
      ],
      allSubForSem: [],
      headerForAllocation: [],
      overallData: [],
      studentData: [],
      defaultLimitDialog: false,
      showAllLecDialog: false,
      loaderForTable: false,
      defaulterLimitDummy: 0,
      defaulterLimit: 0,
      totalDefaulters: 0,
      tab: 0,
      searchValue: '',
      tabNames: [],
      allocationDetails: [],
      startDate: '',
      endDate: '',
      userName: '',
      allStudentList: [],
      allFacultyList: [],
      allSubjectForCourseYear: [],
      objForSelectionCondition: {
        departmentSelection: false,
        courseYearSelection: false,
        courseSelection: false,
        divisionSelection: false,
        batchSelection: false,
        groupSelection: false,
        facultySelection: false,
        studentSelection: false
      },
      isMultiSelectForDivBatch: false,
      isMultiSelectForSub: true,
      selectedStudent: '',
      selectedFaculty: '',
      selectedGroupOfASubject: [],
      selectedBatch: [],
      selectedSubject: null,
      selectedDivision: null,
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedTypeOfAnalysis: '',
      selectedSemester: {},
      departmentList: [],
      courseYearList: [],
      divisionList: [],
      subjectList: [],
      groupNamesList: [],
      facultyList: [],
      batchList: [],
      studentList: [],
      semestersList: [],
      typeListForAnalysis: ['Faculty wise', 'Division wise', 'Batch wise', 'Group wise', 'Single Student wise'],
      selectedInstitute: '',
      instituteName: '',
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      isMobileView: false,
      userData: false
    };
  },
  components: {
    bar,
    pie,
    inputContainer,
  },
  created() {
    this.$store.commit("liveData/set_selectedActivityName", "Attendance Analysis");
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.instituteName = this.$store.getters["instituteData/get_instituteName"];
    this.assignedDepartment = this.$store.getters["liveData/get_accessibleDepartment"].accessibleDepartment;
    this.$store.commit('liveData/set_selectedActivityName', 'Cumulative Attendance')
    this.assignedCourseYearForSubAdmin = this.$store.getters["liveData/get_accessibleCourseYearForSubAdmin"]?.accessibleCourseYearForSubAdmin || [];
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.userData = this.$store.getters["user/get_userData"];
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.getSemesters();
  },
  methods: {
    //group wise function

    downloadExcelReportForGroup() {
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Subject Name', 'Group Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name: tab.length >30 ? tab.splice(0,30) : tab,
          data: []
        }
        const head = [...this.headerForAllocationDummy, ...this.allocationDetails[i].header]
        const stuData = this.allocationDetails[i].item
        const selectedData = this.allocationDetails[i]
        this.createHeadingInExcel(selectedData, titleArr, finalObj)
        this.createExcelHeaderAndData(head, stuData, finalObj, length)
        data.push(finalObj)
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report Group`)
    },
    prepareTableForGroupWise() {

      if (this.allocationDetails.length > 0) {
        this.allocationDetails.forEach((allocation) => {
          allocation.header = this.createHeaderFromLec(allocation)
          allocation.item = this.createDataFromStudent(allocation, 'Group')
        })
      }


    },
    createTabDataForGroup() {
      this.tabNames = []
      this.selectedGroupOfASubject.forEach((grp) => {
        this.tabNames.push(`${this.selectedSubject.subjectName}_${grp}`)
        const subject = this.objForAllSubDetails[this.selectedSubject.subjectId]
        const grpDetail = subject.groupForSubject.find((group) => group.groupName === grp);
        const obj = {
          department: subject.department,
          courseYear: subject.courseYear,
          subjectId: subject.subjectId,
          subjectName: subject.subjectName,
          groupName: grp,
          groupId: grpDetail.groupId,
          type: 'Group',
          header: [],
          item: [],
          conductedLec: [],
          nonConductedLec: [],
          cancelLec: [],
          studentList: []
        }
        obj.studentList = this.getStudentForCriteria(obj)
        this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
      })
    },
    async createTableForGroup() {
      const { min, max } = this.getStartDateEndDate()
      await this.getLectureData({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjects: [this.selectedSubject.subjectId],
        toDate: max,
        fromDate: min
      })
      this.createTabDataForGroup()
      this.prepareTableForGroupWise()
      this.tabChanged()
    },
    // batch wise function
    downloadExcelReportForBatch() {
      const headingColor = 'ff91d2ff'
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Division', 'Subject Name', 'Batch', 'Faculty Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name: tab === 'Overall' ? 'Overall' : `Sub_${i}`,
          data: []
        }
        const selectedData = this.allocationDetails[i]

        if (tab === "Overall") {
          this.createHeadingInExcel(selectedData, titleArr, finalObj)
          if (this.overallHeaders.length > 0 && this.overallData.length > 0) {
            this.overallHeaders.forEach((hed, j) => {
              finalObj.data.push(this.giveExcelObject(hed.text, j + 1, length, true, 'center', headingColor))
              this.overallData.forEach((overallValue, k) => {
                finalObj.data.push(this.giveExcelObject(overallValue[hed.value], j + 1, length + k + 1, false, 'center', ''))
              })
            })
          }
        } else {
          const head = [...this.headerForAllocationDummy, ...this.allocationDetails[i].header]
          const stuData = this.allocationDetails[i].item
          this.createHeadingInExcel(selectedData, titleArr, finalObj)
          this.createExcelHeaderAndData(head, stuData, finalObj, length)
        }
        data.push(finalObj)
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report Batch`)
    },
    prepareTableForBatchWise() {
      if (this.selectedSubject.length > 1) {
        this.allocationDetails[0].overAllData = []
        this.allocationDetails[0].overAllHeader = [...this.headerForAllocationDummy]
        if (this.allocationDetails.length > 0) {
          this.allocationDetails.forEach((allocation) => {
            allocation.overallAttendance = 0
            if (allocation.type === 'Overall') {

            } else {
              allocation.header = this.createHeaderFromLec(allocation)
              allocation.item = this.createDataFromStudent(allocation, 'Batch')
              this.allocationDetails[0].overAllHeader.push({
                text: `${allocation.subjectName}_${allocation.batch}_overall attendance`,
                value: `${allocation.subjectId}_${allocation.batch}`,
              })
            }
          })
          this.setOverAllData()
        }
      } else {
        if (this.allocationDetails.length > 0) {
          this.allocationDetails.forEach((allocation) => {
            allocation.header = this.createHeaderFromLec(allocation)
            allocation.item = this.createDataFromStudent(allocation, 'Batch')
          })
        }

      }

    },
    createTabDataForBatch() {
      this.tabNames = []
      if (this.selectedSubject.length > 1) {
        this.tabNames = ['Overall']
        this.allocationDetails = []
        this.allocationDetails.push({
          type: 'Overall',
          overAllData: [],
        })
        this.selectedSubject.forEach((sub) => {
          this.selectedBatch.forEach((batch) => {
            this.tabNames.push(`${sub.subjectName}_${this.selectedDivision}_${batch}`)
            const subject = this.objForAllSubDetails[sub.subjectId]
            const obj = {
              department: subject.department,
              courseYear: subject.courseYear,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              division: this.selectedDivision,
              batch: batch,
              type: 'Batch',
              header: [],
              item: [],
              conductedLec: [],
              nonConductedLec: [],
              cancelLec: [],
              studentList: []
            }
            obj.studentList = this.getStudentForCriteria(obj)
            this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
          })

        })
      } else {

        this.selectedSubject.forEach((sub) => {
          this.selectedBatch.forEach((batch) => {
            this.tabNames.push(`${sub.subjectName}_${this.selectedDivision}_${batch}`)
            const subject = this.objForAllSubDetails[sub.subjectId]
            const obj = {
              department: subject.department,
              courseYear: subject.courseYear,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              division: this.selectedDivision,
              batch: batch,
              type: 'Batch',
              header: [],
              item: [],
              conductedLec: [],
              nonConductedLec: [],
              cancelLec: [],
              studentList: []
            }
            obj.studentList = this.getStudentForCriteria(obj)
            this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
          })

        })
      }
    },
    async createTableForBatch() {
      const { min, max } = this.getStartDateEndDate()
      let ids = this.selectedSubject.map((data) => data.subjectId)
      await this.getLectureData({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjects: ids,
        division: this.selectedDivision,
        toDate: max,
        fromDate: min
      })
      this.createTabDataForBatch()
      this.prepareTableForBatchWise()
      this.tabChanged()
    },
    // Division wise function
    downloadExcelReportForDivision() {
      const titleColor = 'ffffcc00'
      const headingColor = 'ff91d2ff'
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Division', 'Subject Name', 'Faculty Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name: tab === 'Overall' ? 'Overall' : `Sub_${i}`,
          data: []
        }
        const selectedData = this.allocationDetails[i]
        if (tab === 'Overall') {
          this.createHeadingInExcel(selectedData, titleArr, finalObj)
          if (this.overallHeaders.length > 0 && this.overallData.length > 0) {
            this.overallHeaders.forEach((hed, j) => {
              finalObj.data.push(this.giveExcelObject(hed.text, j + 1, length, true, 'center', headingColor))
              this.overallData.forEach((overallValue, k) => {
                finalObj.data.push(this.giveExcelObject(overallValue[hed.value], j + 1, length + k + 1, false, 'center', ''))
              })
            })
          }
        } else {
          const head = [...this.headerForAllocationDummy, ...this.allocationDetails[i].header]
          const stuData = this.allocationDetails[i].item
          this.createHeadingInExcel(selectedData, titleArr, finalObj)
          this.createExcelHeaderAndData(head, stuData, finalObj, length)
        }
        data.push(finalObj)
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report Division`)
    },
    prepareTableForDivisionWise() {
      if (this.selectedSubject.length > 1) {
        this.allocationDetails[0].overAllData = []
        this.allocationDetails[0].overAllHeader = [...this.headerForAllocationDummy]
        if (this.allocationDetails.length > 0) {
          this.allocationDetails.forEach((allocation) => {
            allocation.overallAttendance = 0
            if (allocation.type === 'Overall') {

            } else {

              allocation.header = this.createHeaderFromLec(allocation)
              allocation.item = this.createDataFromStudent(allocation, 'Division')
              this.allocationDetails[0].overAllHeader.push({
                text: `${allocation.subjectName}_overall attendance`,
                value: allocation.subjectId,
              })
            }
          })
          this.setOverAllData()
        }
      } else {
        if (this.allocationDetails.length > 0) {
          this.allocationDetails.forEach((allocation) => {
            allocation.header = this.createHeaderFromLec(allocation)
            allocation.item = this.createDataFromStudent(allocation, 'Division')
          })
        }

      }

    },
    createTabDataForDivision() {
      this.tabNames = []
      if (this.selectedSubject.length > 1) {
        this.tabNames = ['Overall']
        this.allocationDetails = []
        this.allocationDetails.push({
          type: 'Overall',
          overAllData: [],
        })
        this.selectedSubject.forEach((sub) => {
          this.tabNames.push(`${sub.subjectName}_${this.selectedDivision}`)
          const subject = this.objForAllSubDetails[sub.subjectId]
          const obj = {
            department: subject.department,
            courseYear: subject.courseYear,
            subjectId: subject.subjectId,
            subjectName: subject.subjectName,
            division: this.selectedDivision,
            type: 'Division',
            header: [],
            item: [],
            conductedLec: [],
            nonConductedLec: [],
            cancelLec: [],
            studentList: []
          }
          obj.studentList = this.getStudentForCriteria(obj)
          this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))

        })
      } else {

        this.selectedDivision.forEach((div) => {
          this.tabNames.push(`${this.selectedSubject[0].subjectName}_${div}`)
          const subject = this.objForAllSubDetails[this.selectedSubject[0].subjectId]
          const obj = {
            department: subject.department,
            courseYear: subject.courseYear,
            subjectId: subject.subjectId,
            subjectName: subject.subjectName,
            division: div,
            type: 'Division',
            header: [],
            item: [],
            conductedLec: [],
            nonConductedLec: [],
            cancelLec: [],
            studentList: []
          }
          obj.studentList = this.getStudentForCriteria(obj)
          this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
        })
      }
    },
    async createTableForDivision() {
      const { min, max } = this.getStartDateEndDate()
      let ids = this.selectedSubject.map((data) => data.subjectId)
      await this.getLectureData({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        subjects: ids,
        division: this.selectedDivision,
        toDate: max,
        fromDate: min
      })
      this.createTabDataForDivision()
      this.prepareTableForDivisionWise()
      this.tabChanged()
    },
    //faculty wise analysis functions

    downloadExcelReportForFaculty() {
      const titleColor = 'ffffcc00'
      const headingColor = 'ff91d2ff'
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Division', 'Subject Name', 'Batch', 'Group Name', 'Faculty Name', 'Date Range', 'Report time']
      const titleArr1 = ['Semester Name', 'Faculty Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name:  i=== 0 ? 'Overall' : `sub_${i}`,
          data: []
        }
        const selectedData = this.allocationDetails[i]

        if (i === 0) {
          this.createHeadingInExcel(selectedData, titleArr1, finalObj)
          if (this.overallHeaders.length > 0 && this.overallData.length > 0) {
            this.overallHeaders.forEach((hed, j) => {
              finalObj.data.push(this.giveExcelObject(hed.text, j + 1, length, true, 'center', headingColor))
              this.overallData.forEach((overallValue, k) => {
                finalObj.data.push(this.giveExcelObject(overallValue[hed.value], j + 1, length + k + 1, false, 'center', ''))
              })
            })
          }
        } else {
          const head = [...this.headerForAllocationDummy, ...this.allocationDetails[i].header]
          const stuData = this.allocationDetails[i].item
          this.createHeadingInExcel(selectedData, titleArr, finalObj)
          this.createExcelHeaderAndData(head, stuData, finalObj, length)
        }
        data.push(finalObj)
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report ${this.selectedFaculty.fullName}`)
    },
    prepareHeaderForFacultyTables() {
      this.allocationDetails[0].overAllData = []
      if (this.allocationDetails.length > 0) {
        this.allocationDetails.forEach((allocation) => {
          allocation.overallAttendance = 0
          if (allocation.type === 'Overall') {

          } else {
            const objForOverallArr = {
              department: allocation.department,
              courseYear: allocation.courseYear,
              subjectName: allocation.subjectName,
              allocation: ``,
              conductedCount: allocation.conductedLec.length,
              notConductedCount: allocation.nonConductedLec.length,
              cancelCount: allocation.cancelLec.length,
              allocatedStudentCount: allocation.studentList.length,
              overallAttendance: 0,
            }
            objForOverallArr.allocation = this.createAllocationString(allocation)
            allocation.header = this.createHeaderFromLec(allocation)
            allocation.item = this.createDataFromStudent(allocation)
            if (allocation.studentList.length > 0) {
              objForOverallArr.overallAttendance = (allocation.overallAttendance / allocation.studentList.length).toFixed(2)
            }
            this.allocationDetails[0].overAllData.push(objForOverallArr)

          }
        })
      }

    },
    sortLecAsPerAllocationOfFaculty(criteria) {
      if (this.allLecturesData.length > 0) {
        this.allLecturesData.forEach((lec) => {
          // Check if the lecture matches the common criteria
          const matchesCommonCriteria =
            lec.subjectId === criteria.subjectId &&
            lec.department === criteria.department &&
            lec.courseYear === criteria.courseYear;

          if (!matchesCommonCriteria) return;

          // Determine if the lecture matches the specific type
          let matchesSpecificCriteria = false;
          if (criteria.type === "Batch") {
            matchesSpecificCriteria =
              lec.division === criteria.division && lec.batch === criteria.batch;
          } else if (criteria.type === "Division") {
            matchesSpecificCriteria = lec.division === criteria.division;
          } else if (criteria.type === "Group" && Array.isArray(lec.groupForSubject)) {
            matchesSpecificCriteria = lec.groupForSubject.some(
              (grp) => grp.groupId === criteria.groupId
            );
          }

          if (!matchesSpecificCriteria) return;

          // Sort the lecture based on its status
          if (lec.status === 6) {
            criteria.cancelLec.push(lec);
          } else if (lec.isConducted) {
            criteria.conductedLec.push(lec);
          } else {
            criteria.nonConductedLec.push(lec);
          }
        });
      }
      return criteria;
    },
    getStudentForCriteria(criteria) {
      let arr = []
      if (this.allStudentList.length > 0) {
        this.allStudentList.forEach((stu) => {
          // Check if the lecture matches the common criteria
          const matchesCommonCriteria =
            stu.subjects.includes(criteria.subjectId) &&
            stu.department === criteria.department &&
            stu.courseYear === criteria.courseYear;

          if (!matchesCommonCriteria) return;

          // Determine if the lecture matches the specific type
          let matchesSpecificCriteria = false;
          if (criteria.type === "Batch") {
            matchesSpecificCriteria = stu.division === criteria.division && stu.batch === criteria.batch;
          } else if (criteria.type === "Division") {
            matchesSpecificCriteria = stu.division === criteria.division;
          } else if (criteria.type === "Group" && Array.isArray(stu.assignedGroupForSubject)) {
            matchesSpecificCriteria = stu.assignedGroupForSubject.some((grp) => grp.groupId === criteria.groupId);
          }

          if (!matchesSpecificCriteria) return;

          // Sort the lecture based on its status
          arr.push(stu)
        });
      }
      return arr
    },
    createDataForFacultyAllocation() {
      // this.allLecturesData
      this.tabNames = ['Overall']
      this.allocationDetails = []
      this.lecObjForFaculty = {}
      this.allocationDetails.push({
        type: 'Overall',
        overAllData: [],
      })
      const allAllocation = [...this.selectedFaculty.subjects, ...this.selectedFaculty.assignedGroupForSubject]
      if (this.selectedFaculty.subjects.length > 0) {
        this.selectedFaculty.subjects.forEach((allocation) => {
          const subject = this.objForAllSubDetails[allocation.subjectId]
          let flag = false
          if (this.selectedFaculty.assignedGroupForSubject.length > 0) {
            flag = this.selectedFaculty.assignedGroupForSubject.some((allocation) => allocation.subjectId === subject.subjectId )
          }
          if(flag === false) {
            
            const obj = {
              department: subject.department,
              courseYear: subject.courseYear,
              subjectId: allocation.subjectId,
              subjectName: subject.subjectName,
              header: [],
              item: [],
              conductedLec: [],
              nonConductedLec: [],
              cancelLec: [],
              studentList: []
            }
            if (allocation.batchName && allocation.batchName !== '') {
              obj.batch = allocation.batchName
              obj.division = allocation.division
              obj.type = 'Batch'
              this.tabNames.push(`${subject.subjectName}_${allocation.division}_${allocation.batchName}`)
              obj.studentList = this.getStudentForCriteria(obj)
              this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
  
            } else {
              obj.division = allocation.division
              obj.type = 'Division'
              obj.studentList = this.getStudentForCriteria(obj)
              this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))
              this.tabNames.push(`${subject.subjectName}_${allocation.division}`)
  
  
            }
          }
        })
      }
      if (this.selectedFaculty.assignedGroupForSubject.length > 0) {
        this.selectedFaculty.assignedGroupForSubject.forEach((allocation) => {
          const subject = this.objForAllSubDetails[allocation.subjectId]
          const obj = {
            department: subject.department,
            courseYear: subject.courseYear,
            subjectId: allocation.subjectId,
            subjectName: subject.subjectName,
            header: [],
            item: [],
            conductedLec: [],
            nonConductedLec: [],
            cancelLec: []
          }
          obj.groupName = allocation.groupName
          obj.groupId = allocation.groupId
          obj.type = 'Group'
          this.tabNames.push(`${subject.subjectName}_${allocation.groupName}`)
          obj.studentList = this.getStudentForCriteria(obj)
          this.allocationDetails.push(this.sortLecAsPerAllocationOfFaculty(obj))


        })
      }
    },
    async createTableForFaculty() {
      const { min, max } = this.getStartDateEndDate()
      await this.getLectureData(
        {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.selectedFaculty.uId,
          toDate: max,
          fromDate: min
        }
      )
      this.createDataForFacultyAllocation()
      this.prepareHeaderForFacultyTables()
      this.tabChanged()

    },
    //Student wise analysis functions
    downloadExcelReportForStudent() {
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Student Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name: tab === 'Overall' ? 'Overall' : `Sub_${i}`,
          data: []
        }
        let stuData = []
        let head = []
        if (tab === 'Overall') {
          head = [...this.overallHeadersForStudent]
          stuData = this.allocationDetails[i].overAllData
        } else {
          head = [...this.headerForAllocationDummy, ...this.allocationDetails[i].header]
          stuData = this.allocationDetails[i].item
        }
        const selectedData = this.allocationDetails[i]
        this.createHeadingInExcel(selectedData, titleArr, finalObj)
        if (tab === 'Overall') {
          this.createExcelHeaderAndData(head, stuData, finalObj, length)
          data.push(finalObj)
        } else {
          this.createExcelHeaderAndData(head, stuData, finalObj, length)
          data.push(finalObj)
        }
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report for Student`)
    },
    async getAllLecForStudent() {
      const { min, max } = this.getStartDateEndDate()
      const groupIds = []
      if (Array.isArray(this.selectedStudent.assignedGroupForSubject) && this.selectedStudent.assignedGroupForSubject.length > 0) {
        this.selectedStudent.assignedGroupForSubject.forEach((grp) => {
          groupIds.push(grp.groupId)
        })
      }
      const obj = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedStudent.courseYear,
        division: this.selectedStudent.division,
        groupIds: groupIds,
        subjectIds: this.selectedStudent.subjects,
        toDate: max,
        fromDate: min
      }
      await this.getLectureDataForSingleStudent(obj)

    },
    prepareLecObjectForStudent() {
      this.objForStudentLec = {}
      if (this.allLecturesData.length > 0 && this.selectedStudent.subjects.length > 0) {
        this.selectedStudent.subjects.forEach((sub) => {
          this.objForStudentLec[sub] = []
          this.allLecturesData.forEach((lec) => {
            if (lec.subjectId === sub) {
              this.objForStudentLec[sub].push(lec)
            }

          })
        })
      }
    },
    prepareHeaderAndDataForStudent() {
      this.tabNames = []
      this.allocationDetails = []
      this.tabNames.push('Overall')
      this.allocationDetails.push({
        type: 'Overall',
        overAllData: [],
      })
      if (Array.isArray(this.selectedStudent.subjects) && this.selectedStudent.subjects.length > 0) {
        this.selectedStudent.subjects.forEach((sub) => {
          const subject = this.objForAllSubDetails[sub]
          this.tabNames.push(subject.subjectName)
          const objToEachSubject = {
            subjectName: subject.subjectName,
            department: subject.department,
            courseYear: subject.courseYear,
            item: [],
            conductedLec: [],
            nonConductedLec: [],
            cancelLec: [],
            header: []
          }
          const objForOverall = {
            subjectName: subject.subjectName,
            department: subject.department,
            courseYear: subject.courseYear,
            allocation: 'Division',
            type: 'Division',
            conductedCount: 0,
            attendedCount: 0,
            overallAttendance: 0
          }
          if (this.selectedStudent.batch && this.selectedStudent.batch !== '') {
            objForOverall.allocation = 'Batch'
            objForOverall.type = 'Batch'

          }
          if (Array.isArray(this.selectedStudent.assignedGroupForSubject) && this.selectedStudent.assignedGroupForSubject.some(grp => grp.subjectId === sub)) {
            objForOverall.allocation = 'Group'
            objForOverall.type = 'Group'
          }

          const objStudent = {
            rollNumber: this.selectedStudent.rollNumber ? this.selectedStudent.rollNumber : '-',
            collagePRNNo: this.selectedStudent.collagePRNNo ? this.selectedStudent.collagePRNNo : '-',
            fullName: this.selectedStudent.fullName,
            overallAttendance: 0,
            mentor: this.selectedStudent.assignedMentor && this.selectedStudent.assignedMentor !== '' ? this.semUserObj[this.selectedStudent.assignedMentor]['fullName'] : '-'
          }
          const { headerArr, conductedLec, nonConductedLec, cancelLec, conductedCount, attendedCount, overallAttendance } = this.findCountFromLec(this.objForStudentLec[sub], this.selectedStudent)
          objToEachSubject.conductedLec = conductedLec
          objToEachSubject.nonConductedLec = nonConductedLec
          objToEachSubject.cancelLec = cancelLec
          objToEachSubject.header = [...headerArr]
          if (conductedLec.length > 0) {
            conductedLec.forEach((lec) => {
              if (lec.present.includes(this.selectedStudent.uId)) {
                objStudent[lec.lectureId] = 'Present'
              }
              if (lec.absent.includes(this.selectedStudent.uId)) {
                objStudent[lec.lectureId] = 'Absent'
              }
              if (!lec.absent.includes(this.selectedStudent.uId) && !lec.present.includes(this.selectedStudent.uId)) {
                objStudent[lec.lectureId] = 'NA'
              }
            })
          }
          objToEachSubject.item = [objStudent]
          objForOverall.conductedCount = conductedCount
          objForOverall.attendedCount = attendedCount
          objForOverall.overallAttendance = overallAttendance
          objStudent.overallAttendance = overallAttendance
          this.allocationDetails[0]['overAllData'].push(objForOverall)
          this.allocationDetails.push(objToEachSubject)
        })
      }
    },
    async createTableForStudent() {

      await this.getAllLecForStudent()
      this.prepareLecObjectForStudent()
      this.prepareHeaderAndDataForStudent()
      this.tabChanged()

    },
    async getLectureDataForSingleStudent(obj) {
      this.allLecturesData = []
      this.allFacultyName = []

      try {
        const lecData = await this.lectureRepositoryInstance.getAllLecForAssignedSubjectForStudent(obj)
        this.allLecturesData = lecData.lecture ? lecData.lecture : []
        this.allFacultyName = lecData.fullNames ? lecData.fullNames : []

      } catch (error) {
        console.log('error', error);
      }
    },
    // Overall function below
    async endDateChanged() {
      await this.createAnalysisData()
    },
    showAllLecDialogOpen(data) {
      this.selectedDataForAllocation = data
      this.showAllLecDialog = true
      this.allLecItem = [...this.selectedDataForAllocation.conductedLec, ...this.selectedDataForAllocation.nonConductedLec, ...this.selectedDataForAllocation.cancelLec]
      this.selectedLecForTable = [...this.selectedDataForAllocation.conductedLec]
      if (Array.isArray(this.selectedDataForAllocation.selectedLecForTable) && this.selectedDataForAllocation.selectedLecForTable.length > 0) {
        this.selectedLecForTable = [...this.selectedDataForAllocation.selectedLecForTable]
      }
    },
    createExcelHeaderAndData(head, stuData, finalObj, length) {
      const headingColor = 'ff91d2ff'
      if (head.length > 0 && stuData.length > 0) {
        head.forEach((hed, k) => {
          let value1 = hed.text
          if (hed.text !== 'Name' && hed.value !== 'rollNumber' && hed.value !== 'collagePRNNo' && hed.value !== 'overallAttendance' && hed.value !== 'mentor') {
            value1 = value1 + ' ( ' + hed.startTime + ' - ' + hed.endTime
          }
          finalObj.data.push(this.giveExcelObject(value1, k + 1, length, true, 'center', headingColor))
          stuData.forEach((stu, h) => {
            finalObj.data.push(this.giveExcelObject(stu[hed.value], k + 1, length + h + 1, false, 'center', ''))
          })
        })
      }
    },
    setOverAllData() {
      this.allocationDetails[0].overAllData = this.allocationDetails[1]?.item ? [...this.allocationDetails[1].item] : []
      if (this.allocationDetails[0].overAllData.length > 0) {
        this.allocationDetails[0].overAllData.forEach((stu, i) => {
          stu.overallAttendance = 0
          this.allocationDetails.forEach((allocation, j) => {
            if (j > 0) {
              if (allocation.item[i].applicableLecCount > 0) {
                const percentage = (100 * Number(allocation.item[i].attended) / Number(allocation.item[i].applicableLecCount)).toFixed(2)
                stu[allocation.subjectId] = percentage
                allocation.item[i]['overallAttendance'] = percentage
              } else {
                stu[allocation.subjectId] = 0
                allocation.item[i]['overallAttendance'] = 0
              }
            }
          })
        })
      }
    },
    createHeadingInExcel(selectedData, titleArr, finalObj) {
      const titleColor = 'ffffcc00'
      for (let j = 1; j < titleArr.length + 1; j++) {
        finalObj.data.push(this.giveExcelObject(titleArr[j - 1], 1, j, true, 'center', titleColor))
        if (titleArr[j - 1] === 'Semester Name') {
          finalObj.data.push(this.giveExcelObject(this.selectedSemester.semName, 2, j, false, 'center', ''))
        }
        if (titleArr[j - 1] === 'Department') {
          finalObj.data.push(this.giveExcelObject(selectedData.department, 2, j, false, 'center', ''))
        }
        if (titleArr[j - 1] === 'Course Year') {
          finalObj.data.push(this.giveExcelObject(selectedData.courseYear, 2, j, false, 'center', ''))
        }
        if (titleArr[j - 1] === 'Subject Name') {
          finalObj.data.push(this.giveExcelObject(selectedData.subjectName, 2, j, false, 'center', ''))
        }
        if (titleArr[j - 1] === 'Report time') {
          finalObj.data.push(this.giveExcelObject(this.today, 2, j, false, 'center', ''))
        }
        if (titleArr[j - 1] === 'Date Range') {
          finalObj.data.push(this.giveExcelObject(this.startDate + ' To ' + this.endDate, 2, j, false, 'center', ''))
        }

        if (selectedData.type === 'Group') {
          if (titleArr[j - 1] === 'Group Name') {
            finalObj.data.push(this.giveExcelObject(selectedData.groupName, 2, j, false, 'center', ''))
          }
        }
        if (titleArr[j - 1] === 'Faculty Name') {
          finalObj.data.push(this.giveExcelObject(this.selectedFaculty.fullName, 2, j, false, 'center', ''))
        }
        if (selectedData.type === 'Division' || selectedData.type === 'Batch') {
          if (titleArr[j - 1] === 'Division') {
            finalObj.data.push(this.giveExcelObject(selectedData.division, 2, j, false, 'center', ''))
          }
        }
        if (selectedData.type === 'Batch') {
          if (titleArr[j - 1] === 'Batch') {
            finalObj.data.push(this.giveExcelObject(selectedData.batch, 2, j, false, 'center', ''))
          }
        }

      }
    },
    createHeaderFromLec(allocation) {
      let arr = []
      if (allocation.conductedLec.length > 0) {
        allocation.conductedLec.forEach((lec) => {
          arr.push({
            text: lec.startTime.split('T')[0],
            startTime: convertUTCDateToLocalDate(lec.startTime),
            endTime: convertUTCDateToLocalDate(lec.endTime),
            value: lec.lectureId,
            width: '200px'
          })
        })
      }
      return arr
    },
    createAllocationString(allocation) {
      let string = ''
      if (allocation.type === 'Division') {
        string = `${allocation.division}`
      }
      if (allocation.type === 'Batch') {
        string = `${allocation.division}_${allocation.batch}`
      }
      if (allocation.type === 'Group') {
        string = `${allocation.groupName}`
      }
      return string
    },
    createDataFromStudent(allocation, type = '') {
      let arr = []
      if (allocation.studentList.length > 0) {
        allocation.studentList.forEach((stu) => {
          console.log('stu', stu.assignedMentor && stu.assignedMentor !== '');
          const objStudent = {
            rollNumber: stu.rollNumber ? stu.rollNumber : '-',
            collagePRNNo: stu.collagePRNNo ? stu.collagePRNNo : '-',
            fullName: stu.fullName,
            uId: stu.uId,
            overallAttendance: 0,
            applicableLecCount: 0,
            attended: 0,
            mentor: stu.assignedMentor && stu.assignedMentor !== '' ? this.semUserObj[stu.assignedMentor]['fullName'] : '-'
          }
          if (allocation.conductedLec.length > 0) {
            allocation.conductedLec.forEach((lec) => {
              if (lec.present.includes(stu.uId)) {
                objStudent[lec.lectureId] = 'Present'
                objStudent['attended']++;
              }
              if (lec.absent.includes(stu.uId)) {
                objStudent[lec.lectureId] = 'Absent'
              }
              if (!lec.absent.includes(stu.uId) && !lec.present.includes(stu.uId)) {
                objStudent[lec.lectureId] = 'NA'
              } else {
                objStudent['applicableLecCount']++;

              }
            })
          }
          if (type === 'Division' || type === 'Group') {
            objStudent[allocation.subjectId] = 0
          }
          if (type === 'Batch') {
            objStudent[`${allocation.subjectId}_${objStudent.batch}`] = 0
          }
          if (objStudent.applicableLecCount > 0 && objStudent.attended > 0) {
            objStudent.overallAttendance = ((objStudent.attended * 100) / objStudent.applicableLecCount).toFixed(2)
            allocation.overallAttendance = Number(allocation.overallAttendance) + Number(objStudent.overallAttendance)
            if (type === 'Division' || type === 'Group') {
              objStudent[allocation.subjectId] = objStudent.overallAttendance
            }
            if (type === 'Batch') {
              objStudent[`${allocation.subjectId}_${objStudent.batch}`] = objStudent.overallAttendance
            }
          }
          if (!arr.some((value) => value.uId === objStudent.uId)) {

            arr.push(objStudent)
          }
          if (type === 'Batch') {
            if (!this.allocationDetails[0].overAllData.some((info) => info.uId === objStudent.uId)) {
              this.allocationDetails[0].overAllData.push(objStudent)
            }
          }
        })
      }
      return arr
    },
    rowClass(item) {
      if (Math.round(Number(item.overallAttendance)) > Number(this.defaulterLimit)) {
        return 'nonDefaulter'
      } else {
        return 'defaulterBackgroundColor'
      }
    },
    applyDefaultLimit() {
      this.defaulterLimit = this.defaulterLimitDummy
      this.totalDefaulters = 0
      this.calculateDefaultStudentCount()
    },
    calculateDefaultStudentCount() {
      this.totalDefaulters = 0
      if (this.allocationDetails[this.tab].type !== 'Overall') {
        const arr = this.allocationDetails[this.tab].item
        if (arr.length > 0) {
          arr.forEach((stu) => {
            if (Math.round(Number(stu.overallAttendance)) <= Number(this.defaulterLimit)) {
              this.totalDefaulters++;
            }
          })
        }
      }
    },
    validateDropdownSelection() {
      this.errorForSelection = []

      if (this.selectedTypeOfAnalysis === 'Faculty wise') {
        if (this.selectedFaculty.uId === undefined) {
          this.errorForSelection.push('Please select faculty')
        }

      } else {
        if (this.selectedDepartment === '') {
          this.errorForSelection.push('Please select department')
        }
        if (this.selectedTypeOfAnalysis === 'Single Student wise') {
          if (this.selectedStudent.uId === undefined) {
            this.errorForSelection.push('Please select student')
          }
        } else {
          if (this.selectedCourseYear === '') {
            this.errorForSelection.push('Please select course Year')
          }

          if (this.selectedTypeOfAnalysis === 'Group wise') {

            if (this.selectedGroupOfASubject.length === 0) {
              this.errorForSelection.push('Please select subject / group')
            }

          }
          if (this.selectedTypeOfAnalysis === 'Division wise' || this.selectedTypeOfAnalysis === 'Batch wise') {
            if (Array.isArray(this.selectedDivision)) {
              if (this.selectedDivision.length === 0) {

                this.errorForSelection.push('Please select division')
              }
            } else {
              if (this.selectedDivision === '' || this.selectedDivision === null || this.selectedDivision === undefined) {
                this.errorForSelection.push('Please select division')
              }
            }

            if (this.selectedTypeOfAnalysis === 'Batch wise') {
              if (this.selectedBatch.length === 0) {
                this.errorForSelection.push('Please select batch')

              }
            }
          }
        }
      }


    },
    giveExcelObject(value = '', column, row, bold = false, alignment = 'center', backgroundColor = 'FFFFFF') {
      if (column && row && row > 0 && column > 0) {
        return {
          value: value,
          column: column,
          row: row,
          bold: bold,
          alignment: alignment,
          backgroundColor: backgroundColor,
          border: true
        }
      }
    },
    getCurrentDateTime() {
      const now = new Date();

      // Format date
      const date = now.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });

      // Format time with AM/PM
      const time = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      this.today = `${date} ${time}`
    },
    downloadExcel() {
      if (this.selectedTypeOfAnalysis === 'Faculty wise') {
        this.downloadExcelReportForFaculty()
      }
      if (this.selectedTypeOfAnalysis === 'Group wise') {
        this.downloadExcelReportForGroup()
      }
      if (this.selectedTypeOfAnalysis === 'Division wise') {
        this.downloadExcelReportForDivision()
      }
      if (this.selectedTypeOfAnalysis === 'Batch wise') {
        this.downloadExcelReportForDivision()
      }
      if (this.selectedTypeOfAnalysis === 'Single Student wise') {
        this.downloadExcelReportForStudent()
      }
    },
    getDate(date) {
      return convertUTCDateToLocalDate(date)
    },
    getStartDateEndDate() {
      const currentDate = new Date(this.startDate);
      currentDate.setDate(currentDate.getDate() - 1);
      const startDate = currentDate.toISOString()
      const startDateForLec = startDate.split('T')[0]
      const min = `${startDateForLec}T18:30:00`
      const max = `${this.endDate}T18:30:59`
      return { min, max }
    },
    tabChanged() {

      this.conductedCount = 0
      this.nonConductedCount = 0
      this.cancelCount = 0
      if (this.selectedTypeOfAnalysis === 'Single Student wise') {

        if (this.tab !== 0) {
          this.headerForAllocation = []
          this.studentData = []
          this.headerForAllocation = [...this.headerForAllocationDummy, ...this.allocationDetails[this.tab]['header']]
          this.studentData = [...this.allocationDetails[this.tab]['item']]
          this.conductedCount = this.allocationDetails[this.tab]['conductedLec'].length
          this.nonConductedCount = this.allocationDetails[this.tab]['nonConductedLec'].length
          this.cancelCount = this.allocationDetails[this.tab]['cancelLec'].length
        } else {
          this.overallData = [...this.allocationDetails[0]['overAllData']]
          this.overallHeaders = [...this.allocationDetails[0]['overAllData']]
        }
      }
      if (this.selectedTypeOfAnalysis === 'Faculty wise') {

        if (this.tab !== 0) {
          this.headerForAllocation = []
          this.studentData = []
          this.headerForAllocation = [...this.headerForAllocationDummy, ...this.allocationDetails[this.tab]['header']]
          this.studentData = [...this.allocationDetails[this.tab]['item']]
          this.conductedCount = this.allocationDetails[this.tab]['conductedLec'].length
          this.nonConductedCount = this.allocationDetails[this.tab]['nonConductedLec'].length
          this.cancelCount = this.allocationDetails[this.tab]['cancelLec'].length
        } else {
          this.overallData = [...this.allocationDetails[0]['overAllData']]
          this.overallHeaders = [
            { text: "Department", value: "department", width: '100px' },
            { text: "Course Year", value: "courseYear", width: '100px' },
            { text: "Course", value: "subjectName", width: '200px' },
            { text: "Allocation", value: "allocation", width: '200px' },
            { text: "Total Lectures Conducted", value: "conductedCount" },
            { text: "Total Lectures Not Conducted", value: "notConductedCount" },
            { text: "Total Lectures Cancelled", value: "cancelCount" },
            { text: "Total Students Allocated", value: "allocatedStudentCount" },
            { text: "Overall Attendance (%)", value: "overallAttendance" }
          ]
        }
      }
      if (this.selectedTypeOfAnalysis === 'Group wise') {

        this.headerForAllocation = []
        this.studentData = []
        this.headerForAllocation = [...this.headerForAllocationDummy, ...this.allocationDetails[this.tab]['header']]
        this.studentData = [...this.allocationDetails[this.tab]['item']]
        this.conductedCount = this.allocationDetails[this.tab]['conductedLec'].length
        this.nonConductedCount = this.allocationDetails[this.tab]['nonConductedLec'].length
        this.cancelCount = this.allocationDetails[this.tab]['cancelLec'].length

      }
      if (this.selectedTypeOfAnalysis === 'Division wise' || this.selectedTypeOfAnalysis === 'Batch wise') {
        if (this.selectedSubject.length > 1) {
          if (this.tab !== 0) {
            this.headerForAllocation = []
            this.studentData = []
            this.headerForAllocation = [...this.headerForAllocationDummy, ...this.allocationDetails[this.tab]['header']]
            this.studentData = [...this.allocationDetails[this.tab]['item']]
            this.conductedCount = this.allocationDetails[this.tab]['conductedLec'].length
            this.nonConductedCount = this.allocationDetails[this.tab]['nonConductedLec'].length
            this.cancelCount = this.allocationDetails[this.tab]['cancelLec'].length
          } else {
            this.overallData = [...this.allocationDetails[0]['overAllData']]
            this.overallHeaders = [...this.allocationDetails[0]['overAllHeader']]
          }
        } else {
          this.headerForAllocation = []
          this.studentData = []
          this.headerForAllocation = [...this.headerForAllocationDummy, ...this.allocationDetails[this.tab]['header']]
          this.studentData = [...this.allocationDetails[this.tab]['item']]
          this.conductedCount = this.allocationDetails[this.tab]['conductedLec'].length
          this.nonConductedCount = this.allocationDetails[this.tab]['nonConductedLec'].length
          this.cancelCount = this.allocationDetails[this.tab]['cancelLec'].length
        }


      }
      this.calculateDefaultStudentCount()
    },
    async createAnalysisData() {
      this.allocationDetails = []
      this.tabNames = []
      this.validateDropdownSelection()
      if (this.errorForSelection.length > 0) return
      this.flagForTable = true

      if (this.selectedTypeOfAnalysis === 'Single Student wise') {
        await this.createTableForStudent()
      }
      if (this.selectedTypeOfAnalysis === 'Faculty wise') {
        await this.createTableForFaculty()
      }
      if (this.selectedTypeOfAnalysis === 'Division wise') {
        await this.createTableForDivision()
      }
      if (this.selectedTypeOfAnalysis === 'Batch wise') {
        await this.createTableForBatch()
      }
      if (this.selectedTypeOfAnalysis === 'Division wise') {
        await this.createTableForDivision()
      }
      if (this.selectedTypeOfAnalysis === 'Group wise') {
        await this.createTableForGroup()
      }
    },
    async getLectureData(obj) {
      this.allLecturesData = []
      try {
        const lecData = await this.lectureRepositoryInstance.getLectureForAnyCriteriaForSpecificDateRange(obj)
        this.allLecturesData = lecData.lectures ? lecData.lectures : []
      } catch (error) {
        console.log('error', error);
      }
    },
    checkLecCriteria(lec, stu) {
      let flag = false
      if (Array.isArray(lec.groupForSubject) && lec.groupForSubject.length > 0) {
        if (Array.isArray(this.selectedStudent.assignedGroupForSubject) && this.selectedStudent.assignedGroupForSubject.length > 0) {
          this.selectedStudent.assignedGroupForSubject.forEach((grp) => {
            if (grp.groupId === lec.groupForSubject[0].groupId) {

              flag = true
            }
          })
        }
      } else {

        if (stu.batch && stu.batch !== '') {
          if (lec.batch === stu.batch && lec.division === stu.division) {
            flag = true
          }
        }
        if (lec.division === stu.division && (!lec.batch || lec.batch === '')) {
          flag = true
        }
        if (Array.isArray(lec.multiDivision) && lec.multiDivision.includes(stu.division)) {
          flag = true
        }
      }
      return flag
    },
    findCountFromLec(lecArr, stu) {

      const obj = {
        headerArr: [],
        conductedLec: [],
        nonConductedLec: [],
        cancelLec: [],
        conductedCount: 0,
        attendedCount: 0,
        overallAttendance: 0
      }
      if (lecArr.length > 0) {
        lecArr.forEach((lec) => {
          if (this.checkLecCriteria(lec, stu)) {

            if (lec.status === 6) {
              obj.cancelLec.push(lec)
            } else {
              if (lec.isConducted === true) {
                obj.conductedLec.push(lec)
                if (lec.present.includes(stu.uId) || lec.absent.includes(stu.uId)) {
                  obj.conductedCount++;
                }
                if (lec.present.includes(stu.uId)) {
                  obj.attendedCount++;
                }
                obj.headerArr.push({

                  text: lec.startTime.split('T')[0],
                  startTime: convertUTCDateToLocalDate(lec.startTime),
                  endTime: convertUTCDateToLocalDate(lec.endTime),
                  value: lec.lectureId,
                  width: '200px'
                })
              } else {
                obj.nonConductedLec.push(lec)
              }
            }
          }
        })
        if (obj.conductedCount > 0) {

          obj.overallAttendance = ((obj.attendedCount * 100) / obj.conductedCount).toFixed(2)
        }
      }
      return obj
    },
    async getSubjectsForSem(obj) {
      this.objForAllSubDetails = {}
      try {
        this.allSubForSem = await this.subjectRepositoryInstance.getSubjectsOfSemForAttendanceAnalysis(obj)
        if (this.allSubForSem.length > 0) {
          this.allSubForSem.forEach((sub) => {
            this.objForAllSubDetails[sub.subjectId] = sub
          })
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async getSemUser(obj) {

      try {
        const stuData = await this.semesterUserRepositoryInstance.getSemUserWithNameForAttendanceAnalysis(obj)
        this.semUserObj = {}
        if (Array.isArray(stuData.semesterUsers) && stuData.semesterUsers.length > 0) {
          stuData.semesterUsers.forEach((item) => {
            item.fullName = item.firstName + ' ' + item.middleName + ' ' + item.lastName
            this.semUserObj[item.uId] = item
            if (item.courseYear) {
              this.allStudentList.push(item)
            } else {
              this.allFacultyList.push(item)
            }
          })
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async getSemesters() {
      this.endDate = new Date().toISOString().split('T')[0]
      try {
        this.semestersList = await this.semesterRepositoryInstance.getSemesterNames({
          instituteId: this.selectedInstitute,
        });
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
    async getSemDetails() {

      try {
        this.allDivData = await this.semesterRepositoryInstance.getDivisionsOfASemester({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        });
        this.startDate = this.allDivData.length > 0 ? this.allDivData[0].startDate.split('T')[0] : ''
      } catch (err) {
        console.error(err);
      }
    },
    //Criteria changed functions
    async semesterChanged() {
      this.allStudentList = []
      this.allFacultyList = []
      this.clearAllField()
      this.selectedTypeOfAnalysis = ''
      await this.getSemDetails()
      if (this.allDivData.length > 0) {
        this.allDivData.forEach((div) => {
          if (!this.departmentList.includes(div.department)) {
            this.departmentList.push(div.department);
          }
        });
      }
      await this.getSemUser({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      })
      await this.getSubjectsForSem({
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId
      })

    },
    typeChanged() {
      this.tab = 0
      this.clearAllField()
      if (this.selectedTypeOfAnalysis === 'Faculty wise') {
        this.objForSelectionCondition['facultySelection'] = true
        this.facultyList = [...this.allFacultyList]
      }
      if (this.selectedTypeOfAnalysis === 'Single Student wise') {
        this.objForSelectionCondition['departmentSelection'] = true
        this.objForSelectionCondition['studentSelection'] = true
      }
      if (this.selectedTypeOfAnalysis === 'Group wise') {
        this.isMultiSelectForSub = false
        this.objForSelectionCondition['departmentSelection'] = true
        this.objForSelectionCondition['courseYearSelection'] = true
        this.objForSelectionCondition['courseSelection'] = true
        this.objForSelectionCondition['groupSelection'] = true

      }
      if (this.selectedTypeOfAnalysis === 'Division wise') {
        this.objForSelectionCondition['departmentSelection'] = true
        this.objForSelectionCondition['courseYearSelection'] = true
        this.objForSelectionCondition['courseSelection'] = true
        this.objForSelectionCondition['divisionSelection'] = true

      }
      if (this.selectedTypeOfAnalysis === 'Batch wise') {
        this.objForSelectionCondition['departmentSelection'] = true
        this.objForSelectionCondition['courseYearSelection'] = true
        this.objForSelectionCondition['courseSelection'] = true
        this.objForSelectionCondition['divisionSelection'] = true
        this.objForSelectionCondition['batchSelection'] = true

      }
    },
    departmentChanged() {
      this.flagForTable = false
      this.errorForSelection = []
      this.selectedFaculty = ''
      this.selectedStudent = ''
      this.selectedGroupOfASubject = []
      this.selectedBatch = []
      this.selectedSubject = null
      this.selectedDivision = null
      this.selectedCourseYear = ''
      this.courseYearList = [];
      this.studentList = []

      this.allDivData.forEach((div) => {
        if (div.department === this.selectedDepartment)
          if (!this.courseYearList.includes(div.courseYear)) {
            this.courseYearList.push(div.courseYear);
          }
      });
      if (this.allStudentList.length > 0) {
        this.allStudentList.forEach((item) => {
          if (item.department === this.selectedDepartment) {
            this.studentList.push(item)
          }
        })
      }
    },
    async courseYearChanged() {
      // await this.getSubjectForCy()
      this.flagForTable = false
      this.errorForSelection = []
      this.allSubjectForCourseYear = []
      this.selectedFaculty = ''
      this.selectedStudent = ''
      this.selectedGroupOfASubject = []
      this.selectedBatch = []
      this.selectedSubject = null
      this.selectedDivision = null

      this.divisionList = [];
      this.allDivData.forEach((div) => {
        if (div.department === this.selectedDepartment && div.courseYear === this.selectedCourseYear) {
          if (!this.divisionList.includes(div.division)) {
            this.divisionList.push(div.division);
          }
        }
      });
      this.allSubForSem.forEach((sub) => {
        if (sub.department === this.selectedDepartment && sub.courseYear === this.selectedCourseYear) {
          this.allSubjectForCourseYear.push(sub)
        }
      })
      if (this.allSubjectForCourseYear.length > 0) {
        this.allSubjectForCourseYear.forEach((data) => {
          this.subjectList.push(
            {
              subjectName: data.subjectName,
              subjectId: data.subjectId
            });
        });
      }

    },
    subjectChanged() {
      this.flagForTable = false
      this.errorForSelection = []
      this.selectedGroupOfASubject = []
      this.selectedBatch = []
      this.selectedDivision = null
      this.groupNamesList = []
      if (Array.isArray(this.selectedSubject) && this.selectedSubject.length > 1) {
        this.isMultiSelectForDivBatch = false
      } else {
        this.isMultiSelectForDivBatch = true
      }
      if (this.selectedTypeOfAnalysis === 'Group wise') {
        if (this.allSubjectForCourseYear.length > 0) {
          this.allSubjectForCourseYear.forEach((data) => {
            if (data.subjectId === this.selectedSubject.subjectId && data.groupForSubject && data.groupForSubject.length > 0) {
              data.groupForSubject.forEach((grp) => {
                this.groupNamesList.push(grp.groupName)
              })
            }

          });
        }
      }
      if (this.selectedTypeOfAnalysis === 'Batch wise') {
        this.isMultiSelectForDivBatch = false
      }

    },
    divisionChanged() {
      this.flagForTable = false
      this.errorForSelection = []
      this.selectedBatch = []
      this.batchList = []
      this.allDivData.forEach((div) => {
        if (div.department === this.selectedDepartment && div.courseYear === this.selectedCourseYear) {
          if (Array.isArray(this.selectedDivision)) {
            if (this.selectedDivision.includes(div.division) && div.batchesOfDivision && div.batchesOfDivision.length > 0) {
              div.batchesOfDivision.forEach(element => {
                this.batchList.push(element.batchName)
              });
            }

          } else {
            if (this.selectedDivision === div.division && div.batchesOfDivision && div.batchesOfDivision.length > 0) {
              div.batchesOfDivision.forEach(element => {
                this.batchList.push(element.batchName)
              });
            }
          }
        }
      });
    },
    batchChanged() {
      this.flagForTable = false
      this.errorForSelection = []
    },
    groupChanged() {
      this.flagForTable = false
      this.errorForSelection = []

    },

    facultyChanged() {
      this.flagForTable = false
      this.errorForSelection = []


    },
    fillTabName() {
      this.tabNames = []
    },
    async studentChanged() {
      this.flagForTable = false
      this.errorForSelection = []

    },

    clearAllField() {
      this.isMultiSelectForSub = true
      this.errorForSelection = []
      this.flagForTable = false
      this.selectedFaculty = ''
      this.selectedStudent = ''
      this.selectedGroupOfASubject = []
      this.selectedBatch = []
      this.selectedSubject = null
      this.selectedDivision = null
      this.selectedCourseYear = ''
      this.selectedDepartment = ''
      this.objForSelectionCondition = {
        departmentSelection: false,
        courseYearSelection: false,
        courseSelection: false,
        divisionSelection: false,
        batchSelection: false,
        groupSelection: false,
        facultySelection: false,
        studentSelection: false
      }
    },


  },
};
</script>
<style>
.defaulterBackgroundColor {
  background-color: #fceeee !important;
}

.nonDefaulter {}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1),
.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  position: sticky;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1) {
  left: 0;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  left: 100px;
}
</style>
<style scoped src='./activityCumulativeAttendance.css'></style>
