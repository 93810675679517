<template src="./activityEditSemesterUsers.html"></template>

<script>
import ImportAllDialog from "./importAllDialog/importAllDialog";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import LectureRepository from "../../Repository/Lecture";
import InstituteUserRepository from "../../Repository/InstituteUser";
import UserAuthRepository from "../../Repository/UserAuthentication";
import SubjectRepository from "../../Repository/Subject";
import arrayOps from "../../Services/Utils/arrayOps";
import showStatus from "../../NetworkManager/showStatus";
import { createExcelThroughJson, generateJsonFromExcel } from "../../utils/excel";
import axios from "axios";
import ActivityLoggerRepository from "../../Repository/ActivityLogger";
import SemesterRepository from "../../Repository/Semester";

export default {
  name: "ActivityUsersTree",
  components: {
    ImportAllDialog,
    inputContainer,
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_selectedDivision",
    "tree",
  ],
  data() {
    return {
      selectedSubjectForStudent: [],
      allocationDialogForStudent: false,
      selectedStudentForSub: '',
      selectedFacultyForDelete: [],
      uploadExcelDialog: false,
      isDelete: true,
      studentList2: [],
      updateBulkFile: null,
      allChangesArr: [],
      changesListDialog: false,
      deleteStudentArr: [],
      selectedItem: {},
      selectedIndex: null,
      selectedType: '',
      lecBeforeDate: [],
      isEditable: true,
      dialogForLectureCount: false,
      headersForAllocation: [
        {
          text: 'Sr.No',
          value: 'srNo'
        },
        {
          text: 'Subject Name',
          value: 'subjectName'
        },
        {
          text: 'Allocation Type',
          value: 'type'
        },
        {
          text: 'Allocation',
          value: 'allocation'
        }
      ],
      headersForStudent: [
        // {
        //   text: 'Sr.No',
        //   value: 'srNo',
        //   width: '50px'
        // },
        {
          text: 'Name',
          value: 'fullName',
          width: '180px'
        },

        {
          text: 'Dept | Course Year | Division',
          value: 'criteria',
          width: '200px'

        },
        {
          text: 'Batch',
          value: 'batch',
          width: '100px'
        },
        {
          text: 'Allocated Subjects',
          value: 'allocatedSubjects',
          width: '150px'
        },
        {
          text: 'PRN',
          value: 'collegePRNNo',
          width: '200px'

        },
        {
          text: 'Roll Number',
          value: 'rollNumber',
          width: '200px'

        },
        {
          text: 'Exam seat no',
          value: 'examSeatNumber',
          width: '200px'

        },
        {
          text: 'Specialization',
          value: 'specialization',
          width: '200px'
        },

        {
          text: 'Reporting Date',
          value: 'reportingDate',
          width: '200px'
        },
        {
          text: 'ABC Id',
          value: 'abcIdNumber',
          width: '200px'
        },
        {
          text: 'Gr no',
          value: 'grNumber',
          width: '200px'
        },
        {
          text: 'Mobile No',
          value: 'phone',
          width: '200px'
        },
        {
          text: 'UserName',
          value: 'userName',
          width: '300px'

        }
      ],
      selectedFacultyName: '',
      allStudentList: [],
      allSubjectObj: {},
      allocationDialog: false,
      allSubjects: [],
      allocationData: [],
      designationName: [],
      studentList: [],
      headersForFaculty: [
        {
          text: 'Sr.No',
          value: 'srNo'
        },
        {
          text: 'Faculty Name',
          value: 'fullName'
        },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Designation',
          value: 'designation'
        },
        {
          text: 'Allocated Subjects',
          value: 'allocatedSubjects'
        },
        {
          text: 'Mobile No',
          value: 'phone'
        },
        {
          text: 'UserName',
          value: 'userName'
        },
      ],
      facultyList: [],
      search: '',
      totalFacultiesCount: 0,
      assignedDepartment: [],
      assignedCourseYearForSubAdmin: [],
      semIdForSelectedSem: '',
      semestersAllData: [],
      userData: {},
      selectedSemester: {},
      selectedInstitute: '',
      selectedView: '',
      selectedDepartment: '',
      selectedCourseYear: '',
      selectedDivision: '',
      viewList: ['Student', 'Faculty'],
      departmentList: [],
      courseYearList: [],
      divisionList: [],
      dialog: false,
      fullDescription: '',
      fullNameOFStudent: '',
    };
  },
  created() {
    this.$store.commit("liveData/set_selectedActivityName", "Semester Users");
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.LectureRepositoryInstance = new LectureRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.instituteUserRepositoryInstance = new InstituteUserRepository(this);
    this.activityLoggerInstance = new ActivityLoggerRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.UpdateSemesterUserServiceInstance = new SemesterUserRepository(this);
    this.UserAuthRepositoryServiceInstance = new UserAuthRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.PropsSelectedSemester;
    this.assignedDepartment = this.$store.getters['liveData/get_accessibleDepartment'].accessibleDepartment
    this.assignedCourseYearForSubAdmin = this.$store.getters['liveData/get_accessibleCourseYearForSubAdmin']?.accessibleCourseYearForSubAdmin || []

    this.fetchSemDetails();
  },
  methods: {
    showSubject(item) {
this.selectedStudentForSub = item.fullName
this.allocationDialogForStudent = true
this.selectedSubjectForStudent = item.allocatedSubjects
    },
    showMore(item) {
      this.fullDescription = item.allocatedSubjects;
      this.fullNameOFStudent = item.fullName;
      this.dialog = true;
    },
    downloadExcel() {
      if (this.selectedView === 'Faculty') {
        let data = []
        if (this.facultyList.length > 0) {
          this.facultyList.forEach((fac) => {
            data.push({
              Name: fac.fullName,
              department: fac.department,
              designation: fac.designation,
              phone: fac.phone,
              userName: fac.userName
            })
          })
        }
        createExcelThroughJson(data, 'Faculty list')
      } else {
        let data = []
        if (this.studentList.length > 0) {
          this.studentList.forEach((stu) => {
            data.push({
              "Name": stu.fullName,
              "Semester Name": this.selectedSemester.semName,
              "Department CourseYear Division": stu.department +'_'+ stu.courseYear + '_'+ stu.division,
              "batch": stu.batch,
              "Subject Allocated": stu.allocatedSubjects,
              "Prn": stu.collegePRNNo,
              "Roll No": stu.rollNumber,
              "Exam Seat No": stu.examSeatNumber,
              "Specialization": stu.specialization,
              "Report Date": stu.reportingDate,
              "Abc Id": stu.abcIdNumber,
              "Gr No": stu.grNumber,
              "Phone": stu.phone,
              "UserName": stu.userName,
            })
          })
        }
        createExcelThroughJson(data, 'Student list')
      }
    },
    async parseExcelData() {
      this.uploadExcelDialog = false

      const schema = {
        Sheet1: {
          "Username": {
            validation: {
              type: "textLength",
              required: true,
            },
            variableName: "userName",
          },
          "Roll number": {
            validation: {
              type: "textLength",
              // required: true
            },
            variableName: "rollNumber",
          },
          "Name": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "name",
          },
          "PRN": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "collegePRNNo",
          },
          "Exam seat no": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "examSeatNumber",
          },
          "Specialization": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "specialization",
          },
          "Abc Id": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "abcIdNumber",
          },
          "Gr No": {
            validation: {
              type: "textLength",
              // required: true,
            },
            variableName: "grNumber",
          },

        },
      };
      const value = await generateJsonFromExcel(this.updateBulkFile, schema)
      const objForStudent = {}
      const objForStudentRollNumber = {}
      if (this.studentList.length > 0) {
        this.studentList.forEach((stu) => {
          if (stu.rollNumber && stu.rollNumber !== '' && stu.rollNumber !== '-') {
            objForStudentRollNumber[stu.rollNumber] = stu
          }
          objForStudent[stu.userName] = stu
        })
      }
      let arr = []
      if (Array.isArray(value.data.Sheet1) && value.data.Sheet1.length > 0) {
        value.data.Sheet1.forEach((info) => {
          info.rollNumber = String(info.rollNumber)
          info.examSeatNumber = String(info.examSeatNumber)
          info.abcIdNumber = String(info.abcIdNumber)
          info.grNumber = String(info.grNumber)
          const value = this.processStudentInfo(info, objForStudentRollNumber, objForStudent)
          if (value) {

            arr.push(value)
          }
        })
      }
      if (arr.length > 0) {
        await this.updateSemDetailsInBulk(arr)
        await this.updateBasicDetailsInBulk(arr)
        await this.getStudentDataAndFilterOut()

      }

    },
    processStudentInfo(info, objForRollNumber, objForUserName) {
      const processRollNumberData = (dataObj, key) => {
        info.uId = dataObj[key]['uId'];
        info.instituteId = dataObj[key]['instituteId'];
        info.semId = dataObj[key]['semId'];
        info.department = dataObj[key]['department'];
        info.courseYear = dataObj[key]['courseYear'];
      };

      const cleanUpUndefinedFields = (fields) => {
        fields.forEach(field => {
          if (info[field] === undefined || info[field] === null || info[field] === 'undefined') {
            delete info[field];
          }
        });
      };
      const filedArr = ["rollNumber", "collegePRNNo", "examSeatNumber", "specialization", 'abcIdNumber', 'grNumber']
      if (info.rollNumber !== undefined && info.rollNumber !== '' && info.rollNumber !== '-' && info.rollNumber !== 'undefined') {
        if (Object.keys(objForRollNumber).includes(String(info.rollNumber))) {
          processRollNumberData(objForRollNumber, info.rollNumber);
          cleanUpUndefinedFields(filedArr);
          return info
        } else {
          processRollNumberData(objForUserName, info.userName);
          cleanUpUndefinedFields(filedArr);
          return info
        }
      } else if (Object.keys(objForUserName).includes(info.userName)) {
        processRollNumberData(objForUserName, info.userName);
        cleanUpUndefinedFields(filedArr);
        return info
      }


    },
    // Student related function,
    downloadSampleExcel() {
      let data = []
      if (this.studentList.length > 0) {
        this.studentList.forEach((stu) => {
          const value = {
            "Username": stu.userName,
            "Name": stu.fullName,
            "PRN": stu.collegePRNNo,
            "Roll number": stu.rollNumber,
            "Exam seat no": '',
            "Specialization": '',
            "Abc Id": '',
            "Gr No": '',
          }
          data.push(value)
        })
        createExcelThroughJson(data, 'Sample For Sem details update')

      }


    },
    uploadExcelForUpdate() {

    },
    async updateSemDetailsInBulk(arr) {
      try {
        await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: arr
        })
        showStatus("Semester User updated successfully", 3000, "success", this);
      } catch (error) {
        console.error('error', error);
        showStatus("Something went wrong", 3000, "error", this);

      }

    },
    async deleteSemUser(obj) {
      try {
        await this.semesterUserRepositoryInstance.deleteMultipleSemUser(obj)
        showStatus("Semester User deleted successfully", 3000, "success", this);

      } catch (error) {
        console.error('error', error);
        showStatus("Something went wrong", 3000, "error", this);

      }

    },
    async updateBasicDetailsInBulk(arr) {
      try {
        await this.userRepositoryInstance.updateUserDetail({
          bulkDataArray: arr
        })
      } catch (error) {
        console.error('error', error);
      }

    },
    async updateAuthDetailsInBulk(arr) {
      try {
        await this.UserAuthRepositoryServiceInstance.updateUsersAuthInBulk({
          bulkDataArray: arr
        })
      } catch (error) {
        console.error('error', error);
      }

    },
    async updateDataForStudent() {
      if (this.studentList.length > 0) {
        let arrForAuth = []
        let arrForBasic = []
        let arrForSem = []
        this.studentList.forEach((stu) => {
          const obj = {
            instituteId: stu.instituteId,
            semId: stu.semId,
            department: stu.department,
            courseYear: stu.courseYear,
            uId: stu.uId,
          }
          if (stu.isChangedForBasicDetails === true) {
            obj.abcIdNumber = stu.abcIdNumber ? stu.abcIdNumber : ''
            obj.collegePRNNo = stu.collegePRNNo ? stu.collegePRNNo : ''
            obj.grNumber = stu.grNumber ? stu.grNumber : ''
            arrForBasic.push(obj)
          }
          if (stu.isChangedForSemDetails === true) {
            obj.rollNumber = stu.rollNumber ? stu.rollNumber : ''
            obj.specialization = stu.specialization ? stu.specialization : ''
            obj.examSeatNumber = stu.examSeatNumber ? stu.examSeatNumber : ''
            arrForSem.push(obj)
          }
          if (stu.isChangedForAuthDetails === true) {
            obj.phone = stu.phone ? stu.phone : ''
            obj.userName = stu.userName ? stu.userName : ''
            arrForAuth.push(obj)
          }

        })
        if (arrForAuth.length > 0) {
          await this.updateAuthDetailsInBulk(arrForAuth)
        }
        if (arrForSem.length > 0) {
          await this.updateSemDetailsInBulk(arrForSem)
        }
        if (arrForBasic.length > 0) {
          await this.updateBasicDetailsInBulk(arrForBasic)
        }
      }
      if (this.deleteStudentArr.length > 0) {
        let ids = []
        this.deleteStudentArr.forEach((user) => {
          ids.push(user.uId)
        })
        if (ids.length > 0) {
          await this.deleteSemUser({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uIds: ids
          })
        }

      }
      if (this.selectedFacultyForDelete.length > 0) {
        let ids = []
        this.selectedFacultyForDelete.forEach((user) => {
          ids.push(user.uId)
        })
        if (ids.length > 0) {
          await this.deleteSemUser({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uIds: ids
          })
        }
this.departmentChanged()
      }
      await this.getStudentDataAndFilterOut()

    },
    async getStudentDataAndFilterOut() {
      if (this.selectedView === 'Student') {
        if (this.selectedDepartment === 'All') {
          await this.getStudents(this.departmentList)
        } else {
          await this.getStudents([this.selectedDepartment])

        }
      }
      if (this.selectedCourseYear !== 'All' && this.selectedCourseYear !== '') {
        this.studentList = []

        if (this.allStudentList.length > 0) {

          this.allStudentList.forEach((stu) => {
            if (stu.department === this.selectedDepartment && this.selectedCourseYear === stu.courseYear) {
              this.studentList.push(stu)
            }
          })
        }
      }
      if (this.selectedDivision !== 'All' && this.selectedDivision !== '') {
        if (this.allStudentList.length > 0) {
          this.studentList = []

          this.allStudentList.forEach((stu) => {
            if (stu.department === this.selectedDepartment && this.selectedCourseYear === stu.courseYear && stu.division === this.selectedDivision) {

              this.studentList.push(stu)
            }
          })
        }
      }
    },
    saveChanges() {
      this.allChangesArr = []
      if (this.deleteStudentArr.length > 0) {
        this.deleteStudentArr.forEach((user) => {
          this.allChangesArr.push(`${user.fullName} will be deleted permanently from semester`)
        })

      }
      if (this.studentList.length > 0) {
        this.studentList.forEach((stu) => {
          if (stu.isChangedForBasicDetails === true || stu.isChangedForSemDetails === true || stu.isChangedForAuthDetails === true) {
            let string = `${stu.fullName} changed values are `
            stu.changedValuesArr.forEach((value) => {
              string = string + ' ' + value
            })
            this.allChangesArr.push(string)
          }

        })
      }
      if (this.allChangesArr.length > 0) {

        this.changesListDialog = true
      }


    },
    deleteFaculty() {
      this.allChangesArr = []
      if (this.selectedFacultyForDelete.length > 0) {
        this.selectedFacultyForDelete.forEach((user) => {
          this.allChangesArr.push(`${user.fullName} will be deleted permanently from semester`)
        })

      }
      if (this.allChangesArr.length > 0) {

        this.changesListDialog = true
      }


    },
    async updateAttendanceOnReportDate(obj) {
      try {
        await this.LectureRepositoryInstance.removeStudentFromLecture(obj);
      } catch (error) {
        console.error('error', error);
      }
    },
    async markAbsentOnReportDate(obj) {
      try {
        await this.LectureRepositoryInstance.markAbsentFromReportDate(obj);
      } catch (error) {
        console.error('error', error);
      }
    },
    async openDialogForLectureDetails(item, i, type) {
      this.lecBeforeDate = []
      if (type === 0) {
        this.selectedItem = item
        this.selectedIndex = i
        this.selectedType = type
        try {
          let { uId, instituteId, semId } = item;
          let obj = { uId, instituteId, semId };
          obj.date = new Date(item.reportingDate).toISOString()
          this.lecBeforeDate = await this.LectureRepositoryInstance.getLectureCountOfStudentPresentBeforeDate(obj)
          if (this.lecBeforeDate.length > 0) {
            this.dialogForLectureCount = true

          } else {
            this.updateReportDate(item, i, type)
          }
        } catch (error) {
          console.error('error', error);
          this.updateReportDate(item, i, type)
        }
      } else {
        this.updateReportDate(item, i, type)
      }
      item.isCalenderOn = false
    },
    async updateReportDate(item, i, type) {
      this.dialogForLectureCount = false

      if (type === 0) {
        item.reportingDate = new Date(item.reportingDate).toISOString()

      } else {
        item.reportingDate = ''
      }
      let { uId, instituteId, semId, department, reportingDate, subjects, division, courseYear } = item;
      let obj = { uId, instituteId, semId, department, reportingDate, subjects, division, courseYear };
      obj.today = new Date().toISOString()
      try {

        await this.semesterUserRepositoryInstance.updateSemesterUser({
          bulkDataArray: [obj]
        }
        
        );
        showStatus("Report date updated successfully", 3000, "success", this);

        if (type === 0) {
          this.updateAttendanceOnReportDate(obj)
          this.markAbsentOnReportDate(obj)
        } else {
          obj.reportingDate = '2022-10-07T18:29:59.000Z'
          this.markAbsentOnReportDate(obj)
        }

        item.reportingDate = item.reportingDate.split('T')[0]
        this.$set(this.studentList, i, item);
        // this.onRefresh()
      } catch (error) {
        console.error('error', error);
        showStatus("Something went wrong", 3000, "error", this);

      }
    },
    dateChangedForBulkEdit(data, i) {
      data.isCalenderOn = true
      this.$set(this.studentList, i, data);
    },
    closeDateForEditBulk(data, i) {
      data.isCalenderOn = false
      this.$set(this.studentList, i, data);

    },
    valueChangedForBasicDetails(data, type) {
      data['isChangedForBasicDetails'] = true

      if (!data.changedValuesArr.includes(type)) {
        data.changedValuesArr.push(type)
      }
    },
    valueChangedForSemDetails(data, type) {
      data['isChangedForSemDetails'] = true
      if (!data.changedValuesArr.includes(type)) {
        data.changedValuesArr.push(type)
      }
    },
    valueChangedForAuthDetails(data, type) {
      data['isChangedForAuthDetails'] = true
      if (!data.changedValuesArr.includes(type)) {
        data.changedValuesArr.push(type)
      }
    },

    async getStudents(arr) {
      this.studentList = []
      this.studentList2 = []
      try {
        this.allStudentList = await this.semesterUserRepositoryInstance.getStudentForDepartmentWithNameAndUserName({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: arr,
        })
        if (this.allStudentList.length > 0) {
          this.allStudentList.forEach((stu) => {
            stu.changedValuesArr = []
            stu.isChangedForBasicDetails = false
            stu.isChangedForSemDetails = false
            stu.isChangedForAuthDetails = false
            stu.criteria = `${stu.department}_${stu.courseYear}_${stu.division}`
            stu.allocatedSubjects = []
            if (stu.reportingDate !== undefined && stu.reportingDate !== null && stu.reportingDate !== '') {
              stu.reportingDate = stu.reportingDate.split('T')[0]
            }
            if (stu.subjects && stu.subjects.length > 0) {
              stu.subjects.forEach((sub, i) => {
                  stu.allocatedSubjects.push(this.allSubjectObj[sub].subjectName)
              })
            }
          })
        }
        this.studentList = [...this.allStudentList]
        this.studentList2 = [...this.allStudentList]
      } catch (error) {
        console.error('error', error);
      }

    },
    // faculty related function
    allocationOpen(data) {
      this.allocationDialog = true

      this.allocationData = []
      this.selectedFacultyName = data.fullName
      if (Array.isArray(data.subjects) && data.subjects.length > 0) {
        data.subjects.forEach((sub) => {
          const obj = {
            subjectName: this.allSubjectObj[sub.subjectId].subjectName,
            type: '',
            allocation: '',
          }
          if (sub.batchName && sub.batchName !== '') {
            obj.type = 'Batch'
            obj.allocation = `${sub.division}_${sub.batchName}`
          } else {
            obj.type = 'Division'
            obj.allocation = `${sub.division}`
          }
          this.allocationData.push(obj)
        })
      }
      if (Array.isArray(data.assignedGroupForSubject) && data.assignedGroupForSubject.length > 0) {
        data.assignedGroupForSubject.forEach((sub) => {
          const obj = {
            subjectName: this.allSubjectObj[sub.subjectId].subjectName,
            type: 'Group',
            allocation: `${sub.groupName}`,
          }
          this.allocationData.push(obj)
        })
      }
    },
    async getFaculties(arr) {
      this.facultyList = []
      try {
        this.facultyList = await this.semesterUserRepositoryInstance.getFacultiesForDepartmentWithNameAndUserName({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: arr,
        })
        this.designationObj = {}
        this.designationName = []
        if (this.facultyList.length > 0) {
          this.facultyList.forEach((fac) => {
            if (fac.designation !== '-') {
              if (this.designationObj[fac.designation] !== undefined) {
                this.designationObj[fac.designation]++;
              } else {
                this.designationObj[fac.designation] = 1


              }
            }
          })
        }
        this.designationName = Object.keys(this.designationObj)
      } catch (error) {
        console.error('error', error);
        showStatus("Something went wrong", 3000, "error", this);

      }

    },
    // Drop down function
    divisionChanged() {
      this.isEditable = true
      this.isDelete = true
      if (this.selectedDivision !== 'All') {
        this.studentList = []
        if (this.allStudentList.length > 0) {
          this.studentList = []

          this.allStudentList.forEach((stu) => {
            if (stu.department === this.selectedDepartment && this.selectedCourseYear === stu.courseYear && stu.division === this.selectedDivision) {

              this.studentList.push(stu)
            }
          })
        }
      } else {
        this.studentList = [...this.studentList3]
      }

    },
    courseYearChanged() {
      this.studentList3 = []
      this.selectedDivision = ''
      this.isEditable = true
      this.isDelete = true

      this.divisionList = ['All']
      this.semestersAllData.forEach((sem) => {
        if (sem.department === this.selectedDepartment && sem.courseYear === this.selectedCourseYear) {
          if (!this.divisionList.includes(sem.division)) {
            this.divisionList.push(sem.division)
          }
        }
      })
      if (this.selectedCourseYear !== 'All') {
        this.studentList = []

        if (this.allStudentList.length > 0) {

          this.allStudentList.forEach((stu) => {
            if (stu.department === this.selectedDepartment && this.selectedCourseYear === stu.courseYear) {
              this.studentList.push(stu)
            }
          })
        }
      } else {
        this.studentList = this.studentList2
      }
      this.studentList3 = [...this.studentList]
    },
    viewChanged() {
      this.selectedDepartment = ''
      this.isEditable = true
      this.isDelete = true

      this.selectedCourseYear = ''
      this.selectedDivision = ''
    },
    async departmentChanged() {
      this.isEditable = true
      this.isDelete = true

      this.selectedCourseYear = ''
      this.selectedDivision = ''
      this.courseYearList = ['All']
      let allowedCourseYearForSelectedDepartment = []
      if (this.assignedCourseYearForSubAdmin.length > 0) {
        this.assignedCourseYearForSubAdmin.forEach((cy) => {
          let [course, department1] = cy.split('_')
          if (department1 === this.selectedDept) {
            allowedCourseYearForSelectedDepartment.push(course)
          }
        })
        this.courseYearList = allowedCourseYearForSelectedDepartment
      } else {
        this.semestersAllData.forEach((sem) => {
          if (sem.department === this.selectedDepartment && this.selectedSemester.semName === sem.semName) {
            if (!this.courseYearList.includes(sem.courseYear)) {
              this.courseYearList.push(sem.courseYear)
            }
          }
        })
      }
      await this.getSubjectsForDepartment()

      if (this.selectedView === 'Faculty') {
        if (this.selectedDepartment === 'All') {
          await this.getFaculties(this.departmentList)
        } else {
          await this.getFaculties([this.selectedDepartment])

        }
      }
      if (this.selectedView === 'Student') {
        if (this.selectedDepartment === 'All') {
          await this.getStudents(this.departmentList)
        } else {
          await this.getStudents([this.selectedDepartment])

        }
      }

    },
    // Overall function
    gotoTransferUser() {
      this.$router.push({
        name: "activityTransferUsers",
        params: {
          prop_selectedDepartment: this.prop_selectedDepartment,
          prop_selectedCourseYear: this.prop_selectedCourseYear,
          prop_selectedDivision: this.prop_selectedDivision,
          tree: this.tree,
        },
      });
    },
    async getSubjectsForDepartment() {
      try {
        this.allSubjects = await this.subjectRepositoryInstance.getSubjectNameOfSemesters({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        })
        this.allSubjectObj = {}
        if (this.allSubjects.length > 0) {
          this.allSubjects.forEach((sub) => {
            this.allSubjectObj[sub.subjectId] = sub
          })
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async fetchSemDetails() {

      try {
        let semDetails = await this.semesterRepositoryInstance.getClassbySemesters({
          instituteId: this.selectedInstitute
        });
        this.semestersAllData = semDetails.semesters
        this.createDepartmentList()

      } catch (error) {
        console.error('error', error);
      }
    },
    createDepartmentList() {
      this.departmentList = ['All']

      if (this.semestersAllData.length > 0) {
        this.semestersAllData.forEach((sem) => {
          if (sem.semName === this.PropsSelectedSemester.semName) {
            this.semIdForSelectedSem = sem.semId
            if (!this.departmentList.includes(sem.department)) {
              this.departmentList.push(sem.department)
            }
          }
        })
      }
      //sub admin department access control
      let subAdminAssignedDepartmentWiseLecture = [];
      if (this.assignedDepartment.length > 0) {
        this.departmentList.forEach((data) => {
          if (this.assignedDepartment.includes(data)) {
            subAdminAssignedDepartmentWiseLecture.push(data);
          }
        });
        this.departmentList = subAdminAssignedDepartmentWiseLecture;
      }
    },
    gotoSemestersManagement() {
      this.$router.push({
        name: "activitySemestersManagment",
        params: {
          prop_semName: this.selectedSemester.semName,
        },
      });
    },
  },
};
</script>
<style>
.defaulterBackgroundColor {
  background-color: #fceeee !important;
}

.nonDefaulter {}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1),
.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  position: sticky;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1) {
  left: 0;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  left: 100px;
}
</style>

<style scoped src="./activityEditSemesterUsers.css"></style>
