export default {
  createSemester: '/semester/createSemester',
  getBatchesDetails: '/semester/getBatchesDetails',
  getNamesOfSemesters: '/semester/getNamesOfSemesters',
  getStatus: '/semester/getStatus',
  getSemesters: '/semester/getSemesters',
  getClassbySemesters: '/semester/getSemesters',
  getCoursebySemesters: '/semester/getSemesters',
  getDivisionsOfASemester: '/semester/getDivisionsOfASemester',
  getEditingPermissionOfASemester: '/semester/getEditingPermissionOfASemester',
  getDatesOfADivisionOfASemester: '/semester/getDatesOfADivisionOfASemester',
  getShiftOfADivisionOfASemester: '/semester/getShiftOfADivisionOfASemester',
  getSemestersBySemId: '/semester/getSemestersBySemId',
  getMultipleSemesters: '/semester/getMultipleSemesters',
  getStudentsOfAInstitute: '/semester/getStudentsOfAInstitute',
  getHolidaysOfADepartmentOfASemester: '/semester/getHolidaysOfADepartmentOfASemester',
  getNumberOfBatchesOfADivisionOfASemester: '/semester/getNumberOfBatchesOfADivisionOfASemester',
  getClassTeacherOfADivisionOfASemester: '/semester/getClassTeacherOfADivisionOfASemester',
  getNameOfASemester: '/semester/getNameOfASemester',
  addBatchesDetailsForDivision: '/semester/addBatchesDetailsForDivision',
  removeBatchesDetailsForDivision: '/semester/removeBatchesDetailsForDivision',
  updateSemesterCourseYearResultDetails: '/semester/updateSemesterCourseYearResultDetails',
  updateSemDetails: '/semester/updateSemDetails',
  updateSemesterDatesForADivision: '/semester/updateSemesterDatesForADivision',
  updateSemesterDatesForACourseYear: '/semester/updateSemesterDatesForACourseYear',
  updateSemesterDatesForADepartment: '/semester/updateSemesterDatesForADepartment',
  updateHolidaysForADivision: '/semester/updateHolidaysForADivision',
  updateHolidaysForACourseYear: '/semester/updateHolidaysForACourseYear',
  updateHolidaysForADepartment: '/semester/updateHolidaysForADepartment',
  updateShiftForADivision: '/semester/updateShiftForADivision',
  updateShiftForACourseYear: '/semester/updateShiftForACourseYear',
  updateShiftForADepartment: '/semester/updateShiftForADepartment',
  updateNumberOfBatchesForADivision: '/semester/updateNumberOfBatchesForADivision',
  updateNumberOfBatchesForACourseYear: '/semester/updateNumberOfBatchesForACourseYear',
  updateNumberOfBatchesForADepartment: '/semester/updateNumberOfBatchesForADepartment',
  updateClassTeacherForADivision: '/semester/updateClassTeacherForADivision',
  createDepartment: '/semester/createDepartment',
}
