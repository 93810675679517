// this array used in showing navbar of admin side when admin log in that roles= 2 then this array is set to nav bar list
//Do not change any position within array further operation handel by index only

export const allowedModule = [
  "My Timeline",
  "Institute Management",
  "Academic Management",
  "Manage Users",
  "Lectures Dashboard",
  "Manage Exams",
  "Cumulative Attendance",
  "Feedback Analysis",
  "Syllabus Tracker",
  "Manage assignments",
  "Manage Study Material",
  "Admission Module",
  "Search Students",
  "Search Faculties",
  "Manage Quick Test",
  "Feedback Form",
  "Courses",
  "Media Library",
  "University Events",
];

export const allowedModuleDetails = [
  {
    id: 0,
    // name: 'Timeline',
    isAccess: false,
    subModule: [],
  },
  {
    id: 1,
    isAccess: false,
    // name: 'Institute Management',
    subModule: [
      {
        subId: 0,
        isAccessForSub: false,
      },
      {
        subId: 1,
        isAccessForSub: false,
      },
      {
        subId: 2,
        isAccessForSub: false,
      },
      {
        subId: 3,
        isAccessForSub: false,
      },
      {
        subId: 4,
        isAccessForSub: false,
      },
      {
        subId: 5,
        isAccessForSub: false,
      },
      {
        subId: 6,
        isAccessForSub: false,
      },
      {
        subId: 7,
        isAccessForSub: false,
      },
    ],
  },
  {
    id: 2,
    isAccess: false,
    // name: 'Academic Management',
    subModule: [
      {
        subId: 0,
        isAccessForSub: false,
        subName: "Select Start Date/End Date",
      },
      {
        subId: 1,
        isAccessForSub: false,
        subName: "Holiday management",
      },
      {
        subId: 2,
        isAccessForSub: false,
        subName: "Time slot allocation",
      },
      {
        subId: 3,
        isAccessForSub: false,
        subName: "Set Batch Count",
      },
      {
        subId: 4,
        isAccessForSub: false,
        subName: "Add Students/Faculties",
      },
      {
        subId: 5,
        isAccessForSub: false,
        subName: 'Assign classTeachers/ Mentor'
      },
      {
        subId: 6,
        isAccessForSub: false,
        subName: "Manage courses",
      },
      {
        subId: 7,
        isAccessForSub: false,
        subName: "Allocate faculties to courses",
      },
      {
        subId: 8,
        isAccessForSub: false,
        subName: "Register students to courses",
      },
      {
        subId: 9,
        isAccessForSub: false,
        subName: "Time table management",
      },
      {
        subId: 10,
        isAccessForSub: false,
        subName: "Department management",
      },
      {
        subId: 11,
        isAccessForSub: false,
        subName: "Group For Subject",
      },
    ],
  },
  {
    id: 3,
    isAccess: false,
    // name: 'Manage Users',
    subModule: [],
  },
  {
    id: 4,
    isAccess: false,
    // name: 'Lectures Dashboard',
    subModule: [
      {
        subId: 0,
        subName: "Arrange Lecture",
        isAccessForSub: false,
      },
      {
        subId: 0,
        subName: "Manage Holiday",
        isAccessForSub: false,
      },
      {
        subId: 0,
        subName: "Merge Lecture",
        isAccessForSub: false,
      },
    ],
  },
  {
    id: 5,
    isAccess: false,
    // name: 'Manage Exams',
    subModule: [
      {
        subId: 0,
        subName: "Exam Settings",
        isAccessForSub: false,
      },
      {
        subId: 1,
        subName: "Exam Creation",
        isAccessForSub: false,
      },
      {
        subId: 2,
        subName: "Exam Form",
        isAccessForSub: false,
      },
      {
        subId: 3,
        subName: "Exam Invigilation",
        isAccessForSub: false,
      },
      {
        subId: 4,
        subName: "Exam Hall Ticket",
        isAccessForSub: false,
      },
      {
        subId: 5,
        subName: "Questions",
        isAccessForSub: false,
      },
      {
        subId: 6,
        subName: "Marks",
        isAccessForSub: false,
      },
      {
        subId: 7,
        subName: "Analysis",
        isAccessForSub: false,
      },
      {
        subId: 8,
        subName: "Exam Result",
        isAccessForSub: false,
      },
      {
        subId: 9,
        subName: "Semester Result",
        isAccessForSub: false,
      },
    ],
  },
  // {
  //   id: 6,
  //   isAccess: false,
  //   // name: 'Attendance Analysis',
  //   subModule: [],
  // },
  {
    id: 7,
    isAccess: false,
    // name: 'Cumulative Attendance',
    subModule: [],
  },
  {
    id: 8,
    isAccess: false,
    // name: 'Feedback Analysis',
    subModule: [],
  },
  {
    id: 9,
    isAccess: false,
    // name: 'Syllabus Tracker',
    subModule: [],
  },
  {
    id: 10,
    isAccess: false,
    // name: 'Manage assignments',
    subModule: [],
  },
  {
    id: 11,
    isAccess: false,
    // name: 'Manage Study Material',
    subModule: [],
  },
  {
    id: 12,
    isAccess: false,
    // name: 'Admission Module',
    subModule: [],
  },
  {
    id: 13,
    isAccess: false,
    // name: 'Search Students',
    subModule: [],
  },
  {
    id: 14,
    isAccess: false,
    // name: 'Search Faculties',
    subModule: [],
  },
  // 'Feedback Form', 'Courses', 'Media Library']
  {
    id: 15,
    isAccess: false,
    // name: 'Manage Quick Test',
    subModule: [],
  },
  {
    id: 16,
    isAccess: false,
    // name: 'Feedback Form',
    subModule: [],
  },
  {
    id: 17,
    isAccess: false,
    // name: 'Courses',
    subModule: [],
  },
  {
    id: 18,
    // name: 'Media Library',
    subModule: [],
  },
  {
    id: 19,
    // name: 'University Events',
    subModule: [],
  },
  {
    id: 19,
    // name: 'University Events',
    subModule: [],
  },
];

export const instituteCardsInfo = [
  {
    cardTitle: "Institute Details",
    cardDescription: [
      "Set your institute details here.",
      "These details are used in setting up the system for users.",
    ],
    cardFunction: "gotoInstituteBasicDetails",
  },
  {
    cardTitle: "Academic Data",
    cardDescription: [
      "Set your academic structure that is followed In your institute.",
      "This helps In setting up appropriate courses for the students.",
    ],
    cardFunction: "gotoReferenceAcademicDetails",
  },
  {
    cardTitle: "Infrastructure",
    cardDescription: [
      "Put in your rooms and location for lectures.",
      "This helps in scheduling lectures for the admin and faculties.",
    ],
    cardFunction: "gotoReferenceInfrastructure",
  },
  {
    cardTitle: "Manage Time Slots",
    cardDescription: [
      "Put in your working days and working timings.",
      "This will help the admins and faculties to schedule lectures.",
    ],
    cardFunction: "gotoReferenceShiftDetails",
  },
  {
    cardTitle: "Add Subject/Courses",
    cardDescription: [
      "Put in your working days and working timings.",
      "This will help the admins and faculties to schedule lectures.",
    ],
    cardFunction: "gotoAddSubjectInstituteLevel",
  },
  {
      cardTitle: 'Exam Setting', //Previously Exam Settings
      cardDescription: [
          'Exam form details.',
          'Passing and promotion criteria details.',
          'Hall Ticket details.'
      ],
      cardFunction: 'gotoExamSettings'
  },
  {
    cardTitle: "Additional Facilities",
    cardDescription: [
      "Add the facilities like academic, physical and technical facilities provided for students.",
      "This details will be used for SAR",
    ],
    cardFunction: "gotoAdditionalFacility",
  },
  {
    cardTitle: "Institute Control Access",
    cardDescription: [
      "Add the facilities like academic, physical and technical facilities provided for students.",
      "This details will be used for SAR",
    ],
    cardFunction: "gotoActivityControlAccess",
  },
];

export const AcademicManagement = [
  {
    cardTitle: "Select Start Date/End Date",
    cardDescription: [
      "Please enter the start date and end date for every department.",
      "You can select the department and enter dates at once for all the corresponding course years and divisions.",
      "You can also allocate dates for individual divisions within a department.",
      "These dates will be used while you schedule lectures in timetables.",
      "These dates will also be used while doing the academic planning.",
    ],
    cardFunction: "gotoactivityEditSemesterDates",
  },
  {
    cardTitle: "Holiday management",
    cardDescription: [
      "Please enter the holidays that are planned for this semester.",
      "This data will be used while creating lectures and schedules for every faculty and student.",
      "On a holiday, schedules will not be prepared.",
      "You can choose option as “Confirm” or “Non confirm” depending on the holiday.",
    ],
    cardFunction: "gotoactivityEditSemesterHolidays",
  },
  {
    cardTitle: "Time slot allocation",
    cardDescription: [
      "Please choose the time slots for the respective departments.",
      "Time slots are prepared by you in the Time slot section within “Institute management”.",
      "On mapping the time slots with departments, we will use it for creating time tables.",
      "If time slots are not created, can bring issues in time table allocations.",
    ],
    cardFunction: "gotoactivityEditSemesterShifts",
  },
  {
    cardTitle: "Set Batch Count",
    cardDescription: [
      "Please enter how many batches each department will have.",
      "You can also allocate separate batch counts for every course year and division.",
      "What is a batch - Batches are sub categories in a division.",
    ],
    cardFunction: "gotoactivityEditSemesterBatches",
  },
  {
    cardTitle: "Add Students/Faculties",
    cardDescription: [
      "Please import students/ faculties into this semester.",
      "If you are creating this for the first time, Please make sure you have added the users in the “Manage Users” section first.",
    ],
    cardFunction: "gotoactivityEditSemesterUsers",
  },
  {
    cardTitle: 'Assign classTeachers/ Mentor',
    cardDescription: [
      "Please enter how many batches each department will have.",
      "You can also allocate separate batch counts for every course year and division.",
      "What is a batch - Batches are sub categories in a division.",
    ],
    cardFunction: "gotoactivityEditSemesterClassteacher",
  },
  {
    cardTitle: "Manage courses",
    cardDescription: [
      "Add subjects (Courses) here under a department and a course year.",
      "These subjects will be used while you create admission form.",
      "These subjects will also be used while creating course material later on.",
    ],
    cardFunction: "gotoactivityEditSemesterSubjects",
  },
  {
    cardTitle: "Allocate faculties to courses",
    cardDescription: [
      "Please allocate faculties for subjects.",
      "One Faculty can be assigned to multiple subjects.",
      "Multiple faculties can be assigned to the same subject.",
    ],
    cardFunction: "gotoactivityEditFacultyAllocation",
  },
  {
    cardTitle: "Register students to courses",
    cardDescription: [],
    cardFunction: "gotoactivityEditStudentsRegistration",
  },
  {
    cardTitle: "Time table management",
    cardDescription: [
      "Please add the respective timetables for the divisions.",
      "They will use the semester dates, holidays, time slots, subjects and faculties allocated in the above sections.",
      "Hence, make sure you have completed those sections before you start with this.",
    ],
    cardFunction: "gotoactivityEditSemesterTimetable",
  },
  {
    cardTitle: "Department management",
    cardDescription: [],
    cardFunction: "gotoactivityEditSemesterDept",
  },
  {
    cardTitle: "Group For Subject",
    cardDescription: [],
    cardFunction: "gotoactivitySubjectGroup",
  },
];
