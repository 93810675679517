<template src='./activityCreateSemester.html'>
</template>
<script>
import inputContainer from '../../Components/inputContainer'
import showStatus from '../../NetworkManager/showStatus'
import arrayOps from '../../Services/Utils/arrayOps'
import AcademicReferenceRepository from '../../Repository/AcademicReference'
import SemesterRepository from '../../Repository/Semester'
// import showStatus from "../../NetworkManager/showStatus";

export default {
  name: 'activityCreateSemester',
  components: {
    inputContainer
  },
  data() {
    return {
      tableDataForDepartmentView: [],
      dialogForDepartmentView: false,
      newSemDataTable: [],
      tab: 0,
      academicDetails: [],
      newSemName: '',
      newSemNameForFaculty: '',
      newSemNameForStudent: '',
      selectedDivisions: [],
      selectedDepartments: [],
      selectedCourseYears: [],
      listForDepartment: [],
      listForCy: [],
      listForDivision: [],
      createSemDialog: false,
      selectedDemNameForView: '',
      tableDataForView: [],
      headerForView: [
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Semester Name',
          value: 'semName'
        },
        {
          text: 'Course Year',
          value: 'courseYear'
        },
        {
          text: 'Division',
          value: 'division'
        },
      ],
      isEditableTable: false,
      dialogForSemView: false,
      allSemObj: {},
      semesters: [],
      headersForSemList: [
        {
          text: 'Semester Name',
          value: 'semName'
        },
        {
          text: 'Name Visible to Faculty',
          value: 'semNameForFaculty'
        },
        {
          text: 'Name Visible to Student',
          value: 'semNameForStudent'
        },
        {
          text: 'Status of Sem',
          value: 'isSemStatusActive'
        },
        {
          text: 'Semester View',
          value: 'view'
        },
      ],
      semList: [],
      searchValue: '',
      loading: false,
      semesterName: '',
      departments: [],
      selectedDepartment: '',
      tempDepartment: '',
      showRemoveDepartmentDialog: false,
      courseYears: [],
      selectedCourseYear: '',
      tempCourseYear: '',
      showRemoveCourseYearDialog: false,
      divisions: [],
      academicDetails: [],
      selectedInstitute: '',
      academicTree: {},
      tempDivision: '',
      showRemoveDivisionDialog: false
    }
  },
  created() {
    this.loading = true
    this.$store.commit('liveData/set_selectedActivityName', 'Create, Update and View Semesters')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.academicReferenceRepositoryInstance = new AcademicReferenceRepository(this)
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.getAllSemester()
    this.getDepartmentFromInstituteLevel()
    this.loading = false
  },
  methods: {
    allDepartmentView() {
      this.dialogForDepartmentView = true
    },
    tabChanged() {
      this.newSemDataTable = []
      if (this.selectedDivisions.length > 0) {
        this.selectedDivisions.forEach((div) => {
          const [dept, cy, division] = div.split('_')
          const obj = {
            semName: this.newSemName,
            instituteId: this.selectedInstitute,
            semNameForFaculty: this.newSemNameForFaculty,
            semNameForStudent: this.newSemNameForStudent,
            department: dept,
            courseYear: cy,
            division: division,
          }
          this.newSemDataTable.push(obj)
        })
      }
    },
    async createNewSem() {
      this.createSemDialog = false
      await this.createSem(this.newSemDataTable)
      await this.getAllSemester()

    },
    divisionChanged() {

    },
    courseYearChanged() {
      this.listForDivision = []
      this.selectedDivisions = []

      if (this.academicDetails.length > 0) {
        this.academicDetails.forEach((academic) => {
          if (this.selectedCourseYears.includes(`${academic.department}_${academic.courseYear}`)) {

            this.listForDivision.push(`${academic.department}_${academic.courseYear}_${academic.division}`)
          }

        })
      }
    },
    departmentSelectionChanged() {
      this.listForCy = []
      this.selectedDivisions = []
      if (this.academicDetails.length > 0) {
        this.academicDetails.forEach((academic) => {
          if (this.selectedDepartments.includes(academic.department)) {

            this.listForCy.push(`${academic.department}_${academic.courseYear}`)
          }

        })
      }
      const arr = []
      if (this.selectedCourseYears.length > 0) {
        this.selectedCourseYears.forEach((cy) => {
          const [dept, courseYear] = cy.split('_')
          if (this.selectedDepartments.includes(dept)) {
            arr.push(cy)
          }
        })
      }
      this.selectedCourseYears = [...arr]

    },
    createSemDialogOpen() {
      this.createSemDialog = true
      this.newSemName = ''
      this.newSemNameForFaculty = ''
      this.newSemNameForStudent = ''
      this.selectedDepartments = []
      this.selectedCourseYears = []
      this.selectedDivisions = []
      this.newSemDataTable = []

    },
    async updateSemDetails() {
      if (this.semList.length > 0) {
        for (let i = 0; i < this.semList.length; i++) {
          const sem = this.semList[i];
          if (sem.isChanged === true) {
            try {
              await this.semesterRepositoryInstance.updateSemDetails({
                instituteId: this.selectedInstitute,
                semId: sem.semId,
                isSemStatusActive: sem.isSemStatusActive,
                semNameForFaculty: sem.semNameForFaculty,
                semNameForStudent: sem.semNameForStudent,
              });
              showStatus("Semester updated successfully", 3000, "success", this);

            } catch (error) {
              showStatus("Semester not updated", 3000, "error", this);

            }

          }
        }
      }

      await this.getAllSemester()

    },
    showViewForSem(data) {
      this.dialogForSemView = true
      this.tableDataForView = []
      this.tableDataForView = this.allSemObj[data.semId]
      this.selectedDemNameForView = data.semName

    },
    async getDepartmentFromInstituteLevel() {
      const objToPush = {
        instituteId: this.selectedInstitute
      }
      this.listForDepartment = []
      try {
        this.academicDetails = await this.academicReferenceRepositoryInstance.getDivisionsOfAnInstitute(objToPush)
        if (this.academicDetails.length > 0) {
          this.academicDetails.forEach((academic) => {

            this.listForDepartment.push(academic.department)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    prepareTable() {
      this.semList = []
      const keys = Object.keys(this.allSemObj)
      if (keys.length > 0) {
        keys.forEach((id) => {
          const obj = {
            semId: id,
            semName: '',
            semNameForFaculty: '',
            semNameForStudent: '',
            departmentNameArr: [],
            isSemStatusActive: ''
          }
          if (this.allSemObj[id].length > 0) {
            this.allSemObj[id].forEach((info) => {
              obj.semName = info.semName
              obj.semNameForFaculty = info.semNameForFaculty
              obj.semNameForStudent = info.semNameForStudent
              obj.isSemStatusActive = info.isSemStatusActive ? info.isSemStatusActive : false
              obj.departmentNameArr.push(info.department)
            })
          }
          obj.departmentNameArr = [...new Set(obj.departmentNameArr)]
          this.semList.push(obj)
        })
      }
    },
    async getAllSemester() {
      try {
        const data = await this.semesterRepositoryInstance.getSemesters(
          {
            instituteId: this.selectedInstitute
          }
        )
        this.semesters = data.semesters ? data.semesters : []
        this.allSemObj = {}
        if (this.semesters.length > 0) {
          this.semesters.forEach((sem) => {
            if (this.allSemObj[sem.semId]) {
              this.allSemObj[sem.semId].push(sem)

            } else {
              this.allSemObj[sem.semId] = [sem]
            }
          })
        }
        this.prepareTable()
      } catch (error) {
        console.error('error', error)
      }
    },
    async createSem(arr) {
      try {
        await this.semesterRepositoryInstance.createSemester(arr)

      } catch (err) {
        console.log(err)
        showStatus('Error in creating semester', 1000, 'error', this)
      }
    },
    async createSemester() {
      this.loading = true
      if (this.semesterName.trim() === '') {
        // handle semester name is empty error
      } else {
        const arrayOfObjectsToPush = this.academicDetails.map((academicDetailsObject) => {
          return {
            instituteId: this.selectedInstitute,
            semName: this.semesterName,
            department: academicDetailsObject.department,
            courseYear: academicDetailsObject.courseYear,
            division: academicDetailsObject.division
          }
        })

        try {
          await this.semesterRepositoryInstance.createSemester(arrayOfObjectsToPush)
          this.$store.dispatch('liveData/set_selectedActivity', 7)
          this.$router.push('/activitySemestersManagment')
          showStatus("Semester created successfully", 3000, "success", this);

        } catch (err) {
          console.log(err)
          showStatus('Error in creating semester', 1000, 'error', this)
        }
      }
      this.loading = false
    },
    gotoSemestersManagment() {
      this.$router.push('/activitySemestersManagment')
    },
  }
}
</script>
<style src='./activityCreateSemester.css' scoped></style>
