<template src="./activityEditSemesterSubjects1.html"></template>

<script>
import SubjectInstituteLevelRepository from "../../Repository/SubjectInstituteLevel";
import SubjectRepository from "../../Repository/Subject";
import showStatus from "../../NetworkManager/showStatus";
import auth from "../../Services/Firebase/auth";
import arrayOps from "../../Services/Utils/arrayOps";

//Global Components
import CustomSelect from "../../Components/styleGuide/InputsAndControls/Select.vue";
export default {
  name: "activityEditSemesterSubjects1",
  // props: [
  //   'prop_tabNumber'
  // ],
  components: {
    CustomSelect
  },
  props: [
    "PropsSelectedSemester",
    "prop_selectedDepartment",
    "prop_selectedCourseYear",
    "prop_tree",
    "tejas",
  ],
  computed:{
    isImportCourseDisabled(){
      if (this.subjectDocumentSelectedForCreation.length === 0) {
      return true;
    }
    return this.subjectDocumentSelectedForCreation.some(sub => !sub.subjectComponent || !sub.subjectTypeInWords);
    }
  },
  async created() {
    let tempDept = []
    // console.log("props", this.PropsSelectedSemester)
    this.selectedSemester = this.PropsSelectedSemester;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    if (this.prop_tree !== undefined) {
      this.tree = [...this.prop_tree];
    }
    if (this.tree) {
      for (let i = 0; i < this.tree.length; i++) {
        tempDept.push(this.tree[i].name);
      }
    } else {
      tempDept = [];
    }
    this.departments = arrayOps.sortArray(tempDept)?.result || []
    this.subjectInstituteLevelInstance = new SubjectInstituteLevelRepository(
      this
    );
    this.subjectRepositoryInstance = new SubjectRepository(this);

    if (this.prop_selectedDepartment !== undefined) {
      this.selectedDepartment = this.prop_selectedDepartment;
      this.selectDepartment();
    }
    if (this.prop_selectedCourseYear !== undefined) {
      this.selectedCourseYear = this.prop_selectedCourseYear;
      this.selectCourseYear();
    }
    await this.fetchSubjectDocumentData();
    auth.getSubjectTypeFromFirebase();
    this.subjectTypesFromFirebase = this.$store.getters["liveData/get_subjectTypes"];
  },
  data() {
    return {
      selectedSemester: {},
      inputBg: "#E0E0FF",
      departments: [],
      courseYears: [],
      selectedDepartment: "",
      selectedCourseYear: "",
      tab: 1,
      headersForNormalCourses: [
        {
          text: "Sr No.",
          value: "srNo",
          sortable: false,
        },
        {
          text: "Code",
          value: "subjectCode",
          sortable: false,
        },
        {
          text: "Course Name",
          value: "subjectName",
          sortable: false,
        },
        {
          text: "Group Name",
          value: "",
          sortable: false,
        },
        {
          text: "Credits",
          value: "credits",
          sortable: false,
        },
        {
          text: "Total Hours",
          value: "totalHrsForWeek",
          sortable: false,
        },
        {
          text: "Type",
          value: "subjectType",
          sortable: false,
        },
        {
          text: "Course Coordinator",
          value: "",
          sortable: false,
        },
        {
          text: "Assessment plan",
          value: "",
          sortable: false,
        },
        {
          text: "Session plan",
          value: "",
          sortable: false,
        },
        {
          text: "Course File",
          value: "",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
      ],
      subjectDocumentSelectedForCreation: [],
      addingSubjectModal: false,
      allSubjectDocument: [],
      selectedInstitute: "",
      searchSubject: "",
      headersSubject: [
        {
          text: "SrNo",
          value: "srNo",
          align: "center",
          width:'10%'
        },
        {
          text: "Code",
          value: "subjectCode",
          align: "center",
          width:'10%'
        },
        {
          text: "Course Name",
          value: "subjectName",
          align: "center",
          width:'20%'
        },
        {
          text: "Credits",
          value: "credits",
          align: "center",
          width:'10%'
        },
        {
          text: "Total Hours",
          value: "totalHrsForWeek",
          align: "center",
          width:'10%'
        },
        {
          text: "Select Subject Component",
          value: "subjectComponent",
          align: "center",
          width:'20%'
        },
        {
          text: "Select Subject Type",
          value: "subjectTypeInWords",
          align: "center",
          width:'20%'
        },
      ],
      normalSubjectsForCourseYear: [],
      tree: {},
      subjectTypesOptions: ["Theory", "Practical", "Tutorial"],
      subjectComponentOptions: [
        "Program Core",
        "Program Electives",
        "Open Electives",
        "Summer Project",
        "Internships/Seminars",
        "Final Dissertation",
        "Mini project",
        "Field Work",
      ],
      subjectTypesFromFirebase : {}
    };
  },
  methods: {
    selectDepartment() {
      // this.loading = true;
      let tempCYs = []
      this.courseYears = [];
      this.selectedCourseYear = "";
      // Find the correct index in this.tree where the department matches the selectedDepartment
      const index = this.tree.findIndex(item => item.department === this.selectedDepartment);

      if (index !== -1) {

        // Process the children of the matched department
        for (let i = 0; i < this.tree[index].children.length; i++) {
          tempCYs.push(this.tree[index].children[i].name);
        }
      } else {
        console.log('No matching department found in the tree for:', this.selectedDepartment);
      }

      this.courseYears = arrayOps.sortArray(tempCYs)?.result || []
      // this.loading = false;
    },
    goToNormalCourse(item) {
      this.$router.push({
        name: "activityNormalCourse",
        params: {
          prop_subject: item,
          PropsSelectedSemester: this.selectedSemester,
          prop_selectedDepartment: this.selectedDepartment,
          prop_selectedCourseYear: this.selectedCourseYear,
          tree: this.tree,
        },
      });
    },
    convertSubjectTypeToName(num) {
      let type;
      switch (num) {
        case 0: {
          type = "Theory & Compulsory";
          break;
        }
        case 1: {
          type = "Practical & Compulsory";
          break;
        }
        case 2: {
          type = "Tutorial & Compulsory";
          break;
        }
        case 3: {
          type = "Audit Course & Compulsory";
          break;
        }
        case 4: {
          type = "Theory & Elective";
          break;
        }
        case 5: {
          type = "Practical & Elective";
          break;
        }
        case 6: {
          type = "Tutorial & Elective";
          break;
        }
        case 7: {
          type = "Audit Course & Elective";
          break;
        }

        default:
          type = "Theory";

        // code block
      }
      return type;
    },
    async fetchSubjectDocumentData() {
      // console.log('checkProp1', this.tejas)
      this.allSubjectDocument = [];

      try {
        const res =
          await this.subjectInstituteLevelInstance.getSubjectDocumentOfAInstitute(
            {
              instituteId: this.selectedInstitute,
            }
          );
        res.forEach(item => item.subjectComponent = item.subjectComponent || []);
        this.allSubjectDocument = res;
      } catch (error) {
        console.error("error", error);
        this.allSubjectDocument = [];
      }
    },
    prepareSubjectModal() {
      this.subjectDocumentSelectedForCreation = [];
      this.addingSubjectModal = true;
    },
    async saveToFirebase(document){
      let comb = 'ELECTIVE' + '_' + document.subjectTypeInWords;
      if (!(comb in this.subjectTypesFromFirebase)){
        this.subjectTypesFromFirebase[comb] = Object.keys(this.subjectTypesFromFirebase).length;
        auth.setSubjectTypeToFirebase(this.subjectTypesFromFirebase)
      }
      console.log("firebaseDb aftee", this.subjectTypesFromFirebase)
      // auth.setSubjectTypeToFirebase({
      //   Compulsory_THEORY  : 0
      // });
    },
    getSubjectTypeInNumber(subjectTypeInWords){
      if(subjectTypeInWords === 'Theory'){
        return 10
      }
      if(subjectTypeInWords === 'Practical'){
        return 11
      }
      if(subjectTypeInWords === 'Tutorial'){
        return 12
      }
      if(subjectTypeInWords === 'Audit'){
        return 13
      }
    },
    async createSubjectByDocument() {
      this.step2Progrss = true;
      let arrayToPush = [];
      this.subjectDocumentSelectedForCreation.map(async (document) => {
        const objToPush = {};
        // let foundDocument = this.normalSubjectsForCourseYear.find(
        //   (subject) =>
        //     subject.instituteSubjectId === document.instituteSubjectId && 
        // );
        objToPush.instituteId = this.selectedInstitute;
        objToPush.semId = this.selectedSemester.semId;
        objToPush.department = this.selectedDepartment;
        objToPush.courseYear = this.selectedCourseYear;
        objToPush.groupId = new Date().valueOf();

        objToPush.subjectName = document.subjectName;
        objToPush.subjectCode = document.subjectCode;
        objToPush.subjectType = this.getSubjectTypeInNumber(document.subjectTypeInWords);
        // objToPush.subjectType = document.subjectType || 1;
        objToPush.instituteSubjectId = document.instituteSubjectId;
        objToPush.isForNaac = document.isForNaac;
        objToPush.yearOfIntroduction = document.yearOfIntroduction;
        objToPush.hrsPerSemester = document.hrsPerSemester;
        // if (foundDocument) {
        //   objToPush.subjectId = foundDocument.subjectId;
        // }
        if (document.framework) {
          objToPush.framework = document.framework;
        }
        if (document.subjectGroupName) {
          objToPush.groupName = document.subjectGroupName;
        }

        if (document.subjectComponent) {
          objToPush.subjectComponent = document.subjectComponent;
        }

        if (document.competenceLevel) {
          objToPush.competenceLevel = document.competenceLevel;
        }

        if (document.credits) {
          objToPush.credits = document.credits;
        }

        if (document.totalMarks) {
          objToPush.totalMarks = document.totalMarks;
        }

        if (document.totalCieMarks) {
          objToPush.totalCieMarks = document.totalCieMarks;
        }

        if (document.totalHrsForWeek) {
          objToPush.totalHrsForWeek = document.totalHrsForWeek;
        }

        if (document.subjectObjectiveFile) {
          objToPush.subjectObjectiveFile = document.subjectObjectiveFile;
        }

        if (document.subjectSyllabusFile) {
          objToPush.subjectSyllabusFile = document.subjectSyllabusFile;
        }

        if (document.lectureHrs) {
          objToPush.lectureHrs = document.lectureHrs;
        }

        if (document.tutorialHrs) {
          objToPush.tutorialHrs = document.tutorialHrs;
        }

        if (document.practicalHrs) {
          objToPush.practicalHrs = document.practicalHrs;
        }

        if (document.selfStudyHrs) {
          objToPush.selfStudyHrs = document.selfStudyHrs;
        }

        if (document.totalContactHrs) {
          objToPush.totalContactHrs = document.totalContactHrs;
        }

        if (document.refBookDetails) {
          objToPush.refBookDetails = document.refBookDetails;
        }

        if (document.outcomesFile) {
          objToPush.outcomesFile = document.outcomesFile;
        }

        if (document.instructionMaterialFile) {
          objToPush.instructionMaterialFile = document.instructionMaterialFile;
        }

        if (document.prerequisiteFile) {
          objToPush.prerequisiteFile = document.prerequisiteFile;
        }

        if (document.prerequisite) {
          objToPush.prerequisite = document.prerequisite;
        }

        if (document.methodologyFile) {
          objToPush.methodologyFile = document.methodologyFile;
        }
        arrayToPush.push(objToPush);
      });

      try {
        const response = await this.subjectRepositoryInstance.bulkCreateSubject(
          arrayToPush
        );
        showStatus("Subject added successfully", 3000, "success", this);

        this.selectCourseYear();
      } catch (err) {
        showStatus("Could not connect to server.", 2000, "error", this);
        console.error(err);
      }
      this.addingSubjectModal = false;

      // this.step2Progrss = false;
    },
    gotoSemestersManagment() {
      this.$router.push({
        name: 'activitySemestersManagment',
        params: {
          prop_semName: this.selectedSemester.semName,
        }
      })
    },
    async selectCourseYear() {
      this.loading = true;
      this.normalSubjectsForCourseYear = [];
      // this.subjectGroups = {};
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
      };

      try {
        const response =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            objToPush
          );
        this.normalSubjectsForCourseYear = response.filter(
          (subject) => subject.courseType === "normal"
        );
        // this.groupNameArr = [];
        // this.subjects.map((data) => {
        //   if (data.groupName !== "") {
        //     this.groupNameArr.push(data.groupName);
        //   }
        // });
        // this.groupNameArr.push("Other/ Add New");

        // let diplicateRemove = [...new Set(this.groupNameArr)];
        // this.groupNameArr = diplicateRemove;
      } catch (err) {
        console.error(err);
        this.normalSubjectsForCourseYear = [];
      }

      // if (
      //   this.faculties.filter(
      //     (faculty) => faculty.department === this.selectedDepartment
      //   ).length === 0
      // ) {
      //   this.faculties = [];
      //   let facultyObjects = [];

      //   try {
      //     facultyObjects =
      //       (await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(
      //         objToPush
      //       )) || [];
      //   } catch (err) {
      //     console.error(err);
      //   }

      //   for (let i = 0; i < facultyObjects.length; i++) {
      //     try {
      //       const nameOfFaculty =
      //         await this.userRepositoryInstance.getFullNameOfUser({
      //           uId: facultyObjects[i].uId,
      //         });

      //       if (nameOfFaculty) {
      //         this.facultyNames[facultyObjects[i].uId] = nameOfFaculty;
      //         this.faculties.push({
      //           uId: facultyObjects[i].uId,
      //           name: nameOfFaculty,
      //           department: facultyObjects[i].department,
      //         });
      //       }
      //     } catch (err) {
      //       console.error(err);
      //     }
      //   }
      // }

      // this.subjectGroups = [];

      // for (let i = 0; i < this.subjects.length; i++) {
      //   if (!this.subjectGroups[this.subjects[i].groupId]) {
      //     this.subjectGroups[this.subjects[i].groupId] = [];
      //   }
      //   this.subjectGroups[this.subjects[i].groupId].push(this.subjects[i]);
      // }

      // this.loading = false;
      // this.subjectGroups.slice();

      // try {
      //   const objToPush = {
      //     instituteId: this.selectedInstitute,
      //     semId: this.selectedSemester.semId,
      //     department: this.selectedDepartment,
      //     courseYear: this.selectedCourseYear,
      //   };
      //   let facultyList =
      //     await this.semesterUserRepositoryInstance.getFacultiesOfADepartment(
      //       objToPush
      //     );
      //   let uIdArr = [];
      //   facultyList.map((fac) => {
      //     uIdArr.push(fac.uId);
      //   });
      //   let names = await this.userRepositoryInstance.getFullNameOfUsers({
      //     uIds: uIdArr,
      //   });
      //   names.map((nam) => {
      //     this.nameOfSubjectCo.push(nam.fullName);
      //   });
      // } catch (error) {
      //   console.error(error);
      // }
    },
  },
};
</script>

<style src="./activityEditSemesterSubjects1.css" scoped>
</style>