export default {
  getSubjectNameOfSemesters: "/subject/getSubjectNameOfSemesters",
  getSubjectsOfADepartment: "/subject/getSubjectsOfADepartment",
  getSubjectsOfSemForAttendanceAnalysis: "/subject/getSubjectsOfSemForAttendanceAnalysis",
  getSubjectByIds: "/subject/getSubjectByIds",
  getSubjectExamRules: "/subject/getSubjectExamRules",
  getSubjectsOfMultiDepartment: "/subject/getSubjectsOfMultiDepartment",
  getSubjectsOfAInstitute: "/subject/getSubjectsOfAInstitute",
  getSubjectDetails: "/subject/getSubjectDetails",
  getSubjectName: "/subject/getSubjectName",
  getSubjectsOfAInstituteAndSemId: "/subject/getSubjectsOfAInstituteAndSemId",
  getSubjectDetailsOfACourseYearWithFacultyData: '/subject/getSubjectDetailsOfACourseYearWithFacultyData',
  getSubjectsOfSemesters: "/subject/getSubjectsOfSemesters",
  getSubjectsOfSemestersWithoutCourseYear: "/subject/getSubjectsOfSemestersWithoutCourseYear",
  getSubjectsOfACourseYear: "/subject/getSubjectsOfACourseYear",
  getSubjectsOnlyByIds: "/subject/getSubjectsOnlyByIds",
  getSubjectsOfACourseYearForAdmission:
    "/subject/getSubjectsOfACourseYearForAdmission",
  getFacultiesTeachingASubject: "/subject/getFacultiesTeachingASubject",
  getDetailsOfASubject: "/subject/getSubject",
  getSubjectsOfAInstituteByInstituteId:
    "/subject/getSubjectsOfAInstituteByInstituteId",
  updateGroupName: "/subject/updateGroupName",
  updateGroupNameOfSubject: "/subject/updateGroupNameOfSubject",
  updateSubjectName: "/subject/updateSubjectName",
  updateMultipleSubjectDetails: "/subject/updateMultipleSubjectDetails",
  updateSubjectCredits: "/subject/updateCredits",
  updateCourseImage: "/subject/updateCourseImage",
  updateSubjectCoordinators: "/subject/updateSubjectCoordinators",
  updateFacultiesTeachingASubject: "/subject/updateFacultiesTeachingASubject",
  updateSubjectType: "/subject/updateSubjectType",
  createSubject: "/subject/createSubject",
  bulkCreateSubject: "/subject/bulkCreateSubject",
  deleteSubject: "/subject/deleteSubject",
  getSubjects: "/subject/getSubjects",
  getSubjectsOfAInstituteAndSem: "/subject/getSubjectsOfAInstituteAndSem",
  updateCompetenceAndPrequisite: "/subject/updateCompetenceAndPrequisite",
};
